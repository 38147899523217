var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "summary-cards", on: { click: _vm.cardsClick } },
    [
      _c("div", { staticClass: "logo" }, [
        !_vm.logoName
          ? _c(
              "div",
              { staticClass: "logo-size" },
              [
                _vm.logo
                  ? _c("img", { attrs: { src: _vm.logo } })
                  : [
                      _c(
                        "span",
                        {
                          staticClass:
                            "pic-name text-ellipsis2 center-middle-box",
                        },
                        [_vm._v(_vm._s(_vm.title))]
                      ),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/business/logobg2.png"),
                          alt: "",
                        },
                      }),
                    ],
              ],
              2
            )
          : _vm._e(),
        _vm.logoName
          ? _c("div", { staticClass: "logo-box" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/logo_mask.png"),
                  alt: "",
                },
              }),
              _c("div", { staticClass: "logo-name" }, [
                _vm._v(_vm._s(_vm.logoName)),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "introduction" }, [
        _c(
          "div",
          { staticClass: "introduction-box" },
          [
            _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _vm.type
              ? _c("span", { staticClass: "type-tab" }, [
                  _vm._v(_vm._s(_vm.type)),
                ])
              : _vm._e(),
            _vm.isStoreDetail
              ? [
                  _c(
                    "a",
                    {
                      staticClass: "introduction-back",
                      attrs: { href: "javascript:void(0);" },
                      on: { click: _vm.goPrevBack },
                    },
                    [_vm._v("查看品牌")]
                  ),
                ]
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "btn-size",
                class: this.cardType === 2 ? "btn-size2" : "",
              },
              [
                _vm.collectShow
                  ? _c(
                      "div",
                      {
                        staticClass: "btn-box",
                        class: _vm.isCollect ? "active" : "",
                        on: { click: _vm.collect },
                      },
                      [
                        _vm.isCollect
                          ? _c("img", {
                              staticClass: "btn-icon",
                              attrs: {
                                src: require("@/assets/images/icon/collect.png"),
                              },
                            })
                          : _vm._e(),
                        !_vm.isCollect
                          ? _c("img", {
                              staticClass: "btn-icon",
                              attrs: {
                                src: require("@/assets/images/icon/collect_hover.png"),
                              },
                            })
                          : _vm._e(),
                        !_vm.isCollect
                          ? _c("span", [_vm._v("收藏")])
                          : _vm._e(),
                        _vm.isCollect
                          ? _c("span", [_vm._v("已收藏")])
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                this.cardType === 1 || this.cardType === 2
                  ? _c("BrandClaim", {
                      attrs: { auto: true, propsData: _vm.brandClaimData },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (slot_scoped) {
                              return [
                                _vm.claimShow
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "btn-box",
                                        class:
                                          slot_scoped.data.cliamStatus === 3
                                            ? "active"
                                            : "",
                                      },
                                      [
                                        slot_scoped.data.cliamStatus === 3
                                          ? _c("img", {
                                              staticClass: "btn-icon",
                                              attrs: {
                                                src: require("@/assets/images/icon/claim.png"),
                                              },
                                            })
                                          : _c("img", {
                                              staticClass: "btn-icon",
                                              attrs: {
                                                src: require("@/assets/images/icon/claim_hover.png"),
                                              },
                                            }),
                                        _vm._v(" 认领 "),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        388912113
                      ),
                    })
                  : _vm._e(),
                _vm.shareShow
                  ? _c(
                      "div",
                      {
                        staticClass: "btn-box",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.shareClick.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "btn-icon",
                          attrs: {
                            src: require("@/assets/images/icon/share_hover.png"),
                          },
                        }),
                        _vm._v(" 分享 "),
                      ]
                    )
                  : _vm._e(),
                _vm.shareModalCon
                  ? _c("div", { staticClass: "share-modal" }, [
                      _c("div", { attrs: { id: "triangle-up" } }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.copyLink,
                              expression: "copyLink",
                              arg: "copy",
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:success",
                              value: _vm.copySuccess,
                              expression: "copySuccess",
                              arg: "success",
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:error",
                              value: _vm.copyError,
                              expression: "copyError",
                              arg: "error",
                            },
                          ],
                          staticClass: "share-copy",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.copyClick.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/icon/copy_icon.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" 复制小程序链接 "),
                        ]
                      ),
                      _c("div", { staticClass: "share-line" }),
                      _c("div", { staticClass: "share-main" }, [
                        _c("div", { staticClass: "code-size" }, [
                          _c("div", {
                            staticClass: "border_corner border_corner_left_top",
                          }),
                          _c("div", {
                            staticClass:
                              "border_corner border_corner_right_top",
                          }),
                          _c("div", {
                            staticClass:
                              "border_corner border_corner_left_bottom",
                          }),
                          _c("div", {
                            staticClass:
                              "border_corner border_corner_right_bottom",
                          }),
                          _c("div", { staticClass: "code-box" }, [
                            _c("img", {
                              attrs: { src: _vm.shareCodeImg, alt: "" },
                            }),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "share-word" }, [
                        _vm._v("「 扫码使用千里目小程序 」"),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm.isShowDate
              ? _c("DatePicker", {
                  staticClass: "header-date-serch",
                  staticStyle: { width: "240px" },
                  attrs: {
                    type: "daterange",
                    placeholder: "按日期范围筛选",
                    options: _vm.dateOptions,
                    placement: "bottom-end",
                    separator: " 至 ",
                  },
                  on: { "on-change": _vm.changeDaterange },
                })
              : _vm._e(),
          ],
          2
        ),
        _c("div", { staticClass: "introduction-box" }, [
          _vm.company
            ? _c("span", { staticClass: "content" }, [
                _vm._v("所属公司："),
                _c("b", [_vm._v(_vm._s(_vm.company))]),
                _vm.isShowGsxx
                  ? _c(
                      "span",
                      {
                        staticClass: "gsxx-btn",
                        on: {
                          click: function ($event) {
                            return _vm.onSelect()
                          },
                        },
                      },
                      [_vm._v("（工商信息）")]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.years
            ? _c("span", { staticClass: "content" }, [
                _vm._v("成立时间："),
                _c("b", [_vm._v(_vm._s(_vm.years))]),
              ])
            : _vm._e(),
          _vm.pattern
            ? _c("span", { staticClass: "content" }, [
                _vm._v("经营模式："),
                _c("b", [_vm._v(_vm._s(_vm.pattern))]),
              ])
            : _vm._e(),
          _vm.level
            ? _c("span", { staticClass: "content" }, [
                _vm._v("商圈级别："),
                _c("b", [_vm._v(_vm._s(_vm.level))]),
              ])
            : _vm._e(),
          _vm.grade
            ? _c("span", { staticClass: "content" }, [
                _vm._v("商圈等级："),
                _c("b", [_vm._v(_vm._s(_vm.grade))]),
              ])
            : _vm._e(),
          _vm.occupied_area
            ? _c("span", { staticClass: "content" }, [
                _vm._v("占有面积："),
                _c("b", [_vm._v(_vm._s(_vm.occupied_area) + "km²")]),
              ])
            : _vm._e(),
          _vm.city
            ? _c("span", { staticClass: "content" }, [
                _vm._v("所在城市："),
                _c("b", [_vm._v(_vm._s(_vm.city))]),
              ])
            : _vm._e(),
          _vm.phone
            ? _c("span", { staticClass: "content" }, [
                _vm._v("电话："),
                _c("b", [_vm._v(_vm._s(_vm.phone))]),
              ])
            : _vm._e(),
          _vm.address
            ? _c("span", { staticClass: "content" }, [
                _vm._v("地址："),
                _c("b", [_vm._v(_vm._s(_vm.address))]),
              ])
            : _vm._e(),
          _vm.developer
            ? _c("span", { staticClass: "content" }, [
                _vm._v("开发商："),
                _c("b", [_vm._v(_vm._s(_vm.developer))]),
              ])
            : _vm._e(),
          _vm.cityGrade
            ? _c("span", { staticClass: "content" }, [
                _vm._v("城市级别："),
                _c("b", [_vm._v(_vm._s(_vm.cityGrade))]),
              ])
            : _vm._e(),
          _vm.cityLevel
            ? _c("span", { staticClass: "content" }, [
                _vm._v("城市线级："),
                _c("b", [_vm._v(_vm._s(_vm.cityLevel))]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "data-list" },
        _vm._l(_vm.listData, function (item, index) {
          return _c(
            "div",
            { key: "list_" + index, staticClass: "list-item" },
            [
              _c("div", { staticClass: "item-name" }, [
                _vm._v(_vm._s(item.name)),
              ]),
              _c(
                "div",
                { staticClass: "item-num" },
                [
                  !_vm.isShowDate && _vm.cardType == 2
                    ? [
                        _vm._v(" --"),
                        _c("span", { staticClass: "item-unit" }, [
                          _vm._v(_vm._s(item.unit)),
                        ]),
                      ]
                    : _vm._e(),
                  _vm.isShowDate && _vm.cardType == 2
                    ? [
                        _vm._v(" " + _vm._s(item.num)),
                        _c("span", { staticClass: "item-unit" }, [
                          _vm._v(_vm._s(item.unit)),
                        ]),
                      ]
                    : _vm._e(),
                  !item.vipData && !item.sVipData && _vm.cardType !== 2
                    ? [
                        _vm._v(" " + _vm._s(item.num)),
                        _c("span", { staticClass: "item-unit" }, [
                          _vm._v(_vm._s(item.unit)),
                        ]),
                      ]
                    : _vm._e(),
                  item.vipData && _vm.isVip && _vm.cardType !== 2
                    ? [
                        _vm._v(" " + _vm._s(item.num)),
                        _c("span", { staticClass: "item-unit" }, [
                          _vm._v(_vm._s(item.unit)),
                        ]),
                      ]
                    : _vm._e(),
                  item.sVipData && _vm.isSVip && _vm.cardType !== 2
                    ? [
                        _vm._v(" " + _vm._s(item.num)),
                        _c("span", { staticClass: "item-unit" }, [
                          _vm._v(_vm._s(item.unit)),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              !_vm.isVip && item.vipData
                ? [
                    ["客群覆盖人口总数", "客群覆盖家庭户数"].includes(item.name)
                      ? [
                          _c("Icon", {
                            staticClass: "vip-icon",
                            attrs: { type: "ios-lock-outline" },
                          }),
                          !_vm.isVip && item.vipData
                            ? _c("div", {
                                staticClass: "vip-mask",
                                on: { click: _vm.vipClick },
                              })
                            : _vm._e(),
                        ]
                      : [
                          _c("Icon", {
                            staticClass: "vip-icon",
                            attrs: { type: "ios-lock-outline" },
                          }),
                          !_vm.isVip && item.vipData
                            ? _c("div", {
                                staticClass: "vip-mask",
                                on: { click: _vm.vipClick },
                              })
                            : _vm._e(),
                        ],
                  ]
                : _vm._e(),
              !_vm.isSVip && item.sVipData
                ? [
                    [
                      !["客群覆盖人口总数", "客群覆盖家庭户数"].includes(
                        item.name
                      )
                        ? _c("Icon", {
                            staticClass: "vip-icon",
                            attrs: { type: "ios-lock-outline" },
                          })
                        : _vm._e(),
                      !_vm.isSVip && item.vipData
                        ? _c("div", {
                            staticClass: "vip-mask",
                            on: { click: _vm.vipClick },
                          })
                        : _vm._e(),
                    ],
                  ]
                : _vm._e(),
            ],
            2
          )
        }),
        0
      ),
      _vm.cityIntroduction
        ? _c("div", { staticClass: "city-introduction" }, [
            _vm._v(" " + _vm._s(_vm.cityIntroduction) + " "),
          ])
        : _vm._e(),
      _vm.isShowTips
        ? _c("div", [
            _vm._v(" 线上营销统计结果采用全网平台短视频抽样数据，仅供参考。 "),
          ])
        : _vm._e(),
      _c("ModalUpgrade", {
        attrs: { isShow: _vm.isShow },
        on: {
          close: function ($event) {
            _vm.isShow = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }