<template>
  <div class="page">
    <div class="page-top">
      <p class="page-top-title">线上营销分析</p>
      <p class="page-top-con">
        联合字节跳动、快手、腾讯等主流媒体，为代理商及广告主提供跨媒<br />体短视频营销整体解决方案，助力业绩增长，实现销量暴涨。
      </p>
      <a class="btn" href="https://www.clipworks.com/" target="_blank"
        >了解更多<img src="/images/home/right.png" alt="" />
      </a>
    </div>
    <div class="page-content">
      <div class="item" v-for="item of list" :key="item.id">
        <div class="item-top">
          <img
            class="item-icon"
            height="36"
            width="36"
            :src="item.icon"
            alt=""
          />
          <p>
            <span class="item-title">{{ item.product }}</span>
            <span class="line">|</span>
            <span>{{ item.desc }}</span>
          </p>
          <img class="tool" width="54" height="30" :src="item.number" alt="" />
        </div>
        <div class="item-con">
          <div class="item-left" :class="{ 'custom-left': item.id === 3 }">
            <template v-if="item.id === 1 || item.id === 2">
              <dl v-for="subitem of item.sub" :key="subitem.name">
                <dt>{{ subitem.name }}</dt>
                <dd v-for="son of subitem.child" :key="son">
                  <img
                    width="12"
                    height="12"
                    src="../../assets/images/depthAnalysis/circle.png"
                    alt=""
                  />
                  {{ son }}
                </dd>
              </dl>
            </template>
            <template v-else>
              <dl
                class="custom-dl"
                v-for="subitem of item.sub"
                :key="subitem.title"
              >
                <dt>{{ subitem.title }}</dt>
                <dd>{{ subitem.desc }}</dd>
              </dl>
            </template>
          </div>
          <div class="item-right">
            <img width="438" height="268" :src="item.img" alt="" />
          </div>
        </div>
        <section class="customer">
          <div class="customer-title">客户案例</div>
          <template v-if="item.id === 1">
            <div class="tab-box">
              <RadioGroup v-model="tabindex">
                <Radio
                  v-for="(tab, index) of caseList"
                  :key="tab.id"
                  :label="index"
                >
                  <img
                    width="24"
                    height="24"
                    :src="tabindex === index ? tab.activeIcon : tab.icon"
                    alt=""
                  />
                  <span :class="{ 'radio-checked': tabindex === index }">{{
                    tab.title
                  }}</span>
                </Radio>
              </RadioGroup>
            </div>
            <section class="case-bg1">
              <div class="case-title">
                {{ caseList[tabindex].title }} . 广告场景
              </div>
              <div class="case-box">
                <div class="case-content">
                  <dl v-for="item of caseList[tabindex].child" :key="item.id">
                    <dt>{{ item.name }}</dt>
                    <dd v-for="(sub, index) of item.desc" :key="'sub' + index">
                      <img
                        width="12"
                        height="12"
                        src="../../assets/images/depthAnalysis/circle.png"
                        alt=""
                      />
                      <span>{{ sub }}</span>
                    </dd>
                  </dl>
                </div>
                <div class="cooperation">
                  <p>合作案例</p>
                  <img
                    width="442"
                    height="46"
                    :src="caseList[tabindex].caseImg"
                    alt=""
                  />
                </div>
              </div>
            </section>
            <a :href="item.href" target="_blank" class="more-btn"
              >了解更多
              <img src="../../assets/images/depthAnalysis/more.png" alt=""
            /></a>
          </template>
          <template v-if="item.id === 2">
            <section class="case-bg">
              <div class="case-con">
                <div class="case-title">
                  全时天地在线、wpp、微盟等众多头部广告代理商及品牌客户
                </div>
                <p>
                  <img
                    width="12"
                    height="12"
                    src="../../assets/images/depthAnalysis/circle.png"
                    alt=""
                  />
                  <span
                    >提供无限级部门管理，协助公司内部拍摄资源统筹，人员绩效管理。</span
                  >
                </p>
                <p>
                  <img
                    width="12"
                    height="12"
                    src="../../assets/images/depthAnalysis/circle.png"
                    alt=""
                  />
                  <span>帮助商视频拍摄流程线上化、精细化，提高拍摄效率。</span>
                </p>
                <p>
                  <img
                    width="12"
                    height="12"
                    src="../../assets/images/depthAnalysis/circle.png"
                    alt=""
                  />
                  <span
                    >工作流线上监工，视频/图片制作进度可控信息传达准确，降低运营成本。</span
                  >
                </p>
                <p>
                  <img
                    width="12"
                    height="12"
                    src="../../assets/images/depthAnalysis/circle.png"
                    alt=""
                  />
                  <span
                    >跨媒体投放一键触达，抖音、快手、微信及百度等媒体适配上传素材上传等一键批量操作。</span
                  >
                </p>
                <p>
                  <img
                    width="12"
                    height="12"
                    src="../../assets/images/depthAnalysis/circle.png"
                    alt=""
                  />
                  <span
                    >全方位可视化数据报表，反馈运营数据，了解实际素材投放效果。</span
                  >
                </p>
              </div>
            </section>
            <a :href="item.href" target="_blank" class="more-btn"
              >了解更多
              <img src="../../assets/images/depthAnalysis/more.png" alt=""
            /></a>
          </template>
          <section v-if="item.id === 3">
            <div class="custom-wrap wow animate__fadeInUp">
              <div class="three-title">「 我们制作了500,000+个视频 」</div>
              <div class="custom mb-60">
                <!-- v-show="curVideoList[0].id !== customVideoList[0].id" -->
                <!-- @mouseenter="handleVideoPlay(transIndex)" -->
                <div
                  v-show="transWidth !== 0"
                  class="custom-left-btn custom-btn"
                  @click="handleBtnClickVideoPrev()"
                  @mouseenter="handleVideoPlay(cusActiveIdx)"
                ></div>
                <!-- v-show="
                    curVideoList[curVideoList.length - 1].id !==
                    customVideoList[customVideoList.length - 1].id
                  " -->

                <!-- @mouseenter="handleVideoPlay(curVideoList.length - 1)" -->
                <!-- @mouseenter="handleVideoPlay(transIndex, 'right')" -->
                <div
                  v-show="transWidth < 900"
                  class="custom-right-btn custom-btn"
                  @click="handleBtnClickVideoNext()"
                  @mouseenter="handleVideoPlay(cusActiveIdx)"
                ></div>
                <!--   curVideoList-->
                <div
                  class="custom-item"
                  v-for="(item, index) in customVideoList"
                  :key="item.id"
                  :class="{
                    customActive: cusActiveIdx === index,
                  }"
                  :style="{ transform: 'translateX(-' + transWidth + 'px)' }"
                  @mouseenter="cusHover(item, index)"
                >
                  <!-- 播放按钮 -->
                  <div
                    v-if="curShowMutedBtnIdx === index"
                    class="voice-btn"
                    @click="handleVideoMuted(index)"
                    @mouseenter="handleVideoPlay(index)"
                  >
                    <img
                      v-if="!muted"
                      src="../../assets/images/depthAnalysis/open-voice.png"
                      alt=""
                    />
                    <img
                      v-if="muted"
                      src="../../assets/images/depthAnalysis/close-voice.png"
                      alt=""
                    />
                  </div>

                  <video
                    :autopaly="cusActiveIdx === index ? true : false"
                    :muted="muted"
                    :poster="item.cover ? item.cover : ''"
                    ref="videoView"
                    loop
                    class="custom-video"
                    onMouseOver="this.play()"
                    onMouseOut="this.pause()"
                    :src="item.videoAddr"
                  >
                    <source src="" type="video/mp4" />
                    <source src="" type="video/ogg" />
                    <source src="" type="video/webm" />
                    <object data="" width="320" height="240">
                      <embed src="" width="320" height="240" />
                    </object>
                  </video>
                </div>
              </div>
              <div class="three-title mb-50">
                「 为垂直行业提供个性化定制方案 」
              </div>
              <!-- 轮播 -->

              <div class="industry-wrap mb-50">
                <div class="rolling">
                  <div
                    class="industry"
                    v-for="(item, index) in industry"
                    :key="index"
                  >
                    <img class="industry-icon" :src="item.icon" alt="" />
                    <div class="industry-name">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
                <div class="rolling">
                  <div
                    class="industry"
                    v-for="(item, index) in industry"
                    :key="index"
                  >
                    <img class="industry-icon" :src="item.icon" alt="" />
                    <div class="industry-name">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a :href="item.href" target="_blank" class="more-btn mt0"
              >了解更多
              <img src="../../assets/images/depthAnalysis/more.png" alt=""
            /></a>
          </section>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { GetCusList } from "@/service";
export default {
  name: "onlineAnalysis",
  data() {
    return {
      tabindex: 0,
      list: [
        {
          id: 1,
          product: "闪投",
          desc: "提供主流媒体多账户运营、广告批量投放、广告效果归因等整体营销解决方案",
          content:
            "专业易用地理可视化平台，10分钟打造位置资产“一张图”，实现全景可视化管理。Excel一键导入成图，资产分布一目了然。资产状态分类可视，状态变更实时预警。运维人员科学调度，经营数据多维度分析。",
          href: "https://www.clipworks.com/product/ad",
          icon: require("../../assets/images/depthAnalysis/online-icon1.png"),
          number: require("../../assets/images/depthAnalysis/01.png"),
          img: require("../../assets/images/depthAnalysis/online2.png"),
          sub: [
            {
              name: "批量搭建广告",
              child: [
                "多账户批量创建广告",
                "智能模板创建广告",
                "定时分批提交广告",
              ],
            },
            {
              name: "跨媒体多账户管理",
              child: ["跨媒体管理账户", "多投放账户管理", "多产品账户管理"],
            },
            {
              name: "自动发送智能时报",
              child: [
                "自动发送报表",
                "自定义报表时间间隔",
                "自定义投后数据指标",
              ],
            },
          ],
        },
        {
          id: 2,
          product: "启量",
          desc: "脚本、演员、场景、成片、数据等一站式短视频创意生产流程精细化管理平台",
          content:
            "通过全面的客群和环境分析来解决店铺开在哪的问题，平台包含海量的城市人口商业地理数据，通过AI构建贴合客户个性化需求的选址模型，一键推荐潜力区域，并对目标点位精准评估，降低选址成本，提升店铺选址的成功率。",
          href: "https://www.clipworks.com/product/creative",
          icon: require("../../assets/images/depthAnalysis/online-icon2.png"),
          number: require("../../assets/images/depthAnalysis/02.png"),
          img: require("../../assets/images/depthAnalysis/online4.png"),
          sub: [
            {
              name: "整合创意生产需求",
              child: ["视频拍摄需求", "图片拍摄需求", "视频混剪需求"],
            },
            {
              name: "智能生产流程管理",
              child: ["生产流程管理", "脚本成片审批", "成片一键投放"],
            },
            {
              name: "广告效果数据分析",
              child: ["广告效果数据分析", "单物料数据透视", "数据透视总览"],
            },
          ],
        },
        {
          id: 3,
          product: "瞬意",
          desc: "一个集剪辑工具、视频模版、视频拍摄服务于一体的短视频智能创意平台",
          content:
            "为企业定位门店目标客户群体，深度刻画用户画像，辅助客户在市场洞察、经营提升、精准营销等方面作出更科学、更准确、更高效的决策。",
          href: "https://v.clipworks.com/",
          icon: require("../../assets/images/depthAnalysis/online-icon3.png"),
          number: require("../../assets/images/depthAnalysis/03.png"),
          img: require("../../assets/images/depthAnalysis/online6.png"),
          sub: [
            {
              title: "智能剪辑工具",
              desc: "视频编辑，音频处理、字幕处理、AI工具等助力提升创作效率",
            },
            {
              title: "海量模板视频",
              desc: "提供海量精美短视频模板,可供您在线编辑制作短视频",
            },
            {
              title: "短视频定制服务",
              desc: "承接短视频创意、本地生活服务、商品展示、电商导流、TVC、\n 动画等视频需求，可提供专业团队上门拍摄，后期制作等服务",
            },
          ],
        },
      ],
      industry: [
        {
          name: "餐饮美食",
          icon: require("../../assets/images/depthAnalysis/industry1.png"),
        },
        {
          name: "电商零售",
          icon: require("../../assets/images/depthAnalysis/industry2.png"),
        },
        {
          name: "游戏网服",
          icon: require("../../assets/images/depthAnalysis/industry3.png"),
        },
        {
          name: "婚恋交友",
          icon: require("../../assets/images/depthAnalysis/industry4.png"),
        },
        {
          name: "IT互联网",
          icon: require("../../assets/images/depthAnalysis/industry5.png"),
        },
        {
          name: "医疗保健",
          icon: require("../../assets/images/depthAnalysis/industry6.png"),
        },
        {
          name: "生活母婴",
          icon: require("../../assets/images/depthAnalysis/industry7.png"),
        },
        {
          name: "金融保险",
          icon: require("../../assets/images/depthAnalysis/industry8.png"),
        },
        {
          name: "文化娱乐",
          icon: require("../../assets/images/depthAnalysis/industry9.png"),
        },
        {
          name: "旅行酒店",
          icon: require("../../assets/images/depthAnalysis/industry10.png"),
        },
        {
          name: "教育培训",
          icon: require("../../assets/images/depthAnalysis/industry11.png"),
        },
      ],
      transWidth: 0,
      isShowNextBtn: true,
      videoElement: [],
      muted: true,
      cusActiveIdx: 0,
      transIndex: 0,
      transIndex2: 0,
      curShowMutedBtnIdx: 0,
      customVideoList: [
        {
          id: 2,
          videoName: "MY-浮生为清歌-专业演员-剧情3-cm-7.12",
          videoAddr:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/MY-浮生为清歌-专业演员-剧情3-cm-7.12.mp4",
          cover:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/cover/MY-浮生为清歌-专业演员-剧情3-cm-7.12.jpg",
        },
        {
          id: 3,
          videoName: "教育-01",
          videoAddr:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/教育-01.mp4",
          cover:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/cover/教育-01.jpg",
        },
        {
          id: 4,
          videoName: "佛洛依德测试互动剧",
          videoAddr:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/佛洛依德测试互动剧.mp4",
          cover:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/cover/佛洛依德测试互动剧.jpg",
        },
        {
          id: 5,
          videoName: "电脑主机",
          videoAddr:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/电脑主机.mp4",
          cover:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/cover/电脑主机.jpg",
        },
        {
          id: 6,
          videoName: "soul-电影院1",
          videoAddr:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/soul-电影院1.mp4",
          cover:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/cover/soul-电影院1.jpg",
        },
        {
          id: 7,
          videoName: "餐饮-牛排家02",
          videoAddr:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/餐饮-牛排家02.mp4",
          cover:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/cover/餐饮-牛排家02.jpg",
        },
        {
          id: 8,
          videoName: "电商-淘特-switch游戏卡",
          videoAddr:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/电商-淘特-switch游戏卡.mp4",
          cover:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/cover/电商-淘特-switch游戏卡.jpg",
        },
        {
          id: 9,
          videoName: "游戏8",
          videoAddr:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/游戏8.mp4",
          cover:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/cover/游戏8.jpg",
        },
        {
          id: 10,
          videoName: "餐饮-深夜食堂",
          videoAddr:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/餐饮-深夜食堂.mp4",
          cover:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/cover/餐饮-深夜食堂.jpg",
        },
        {
          id: 11,
          videoName: "小说类-实拍",
          videoAddr:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/小说类-实拍.mp4",
          cover:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/cover/小说类-实拍.jpg",
        },
        {
          id: 12,
          videoName: "京东方智慧一体机-宣传片",
          videoAddr:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/京东方智慧一体机-宣传片.mp4",
          cover:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/cover/京东方智慧一体机-宣传片.jpg",
        },
        {
          id: 13,
          videoName: "MY-圣思卓智-品牌-口播1-10.8-cm",
          videoAddr:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/MY-圣思卓智-品牌-口播1-10.8-cm.mp4",
          cover:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/cover/MY-圣思卓智-品牌-口播1-10.8-cm.jpg",
        },
        {
          id: 14,
          videoName: "楼兰横版",
          videoAddr:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/楼兰横版.mp4",
          cover:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/cover/楼兰横版.jpg",
        },
        {
          id: 15,
          videoName: "社交-Ateen",
          videoAddr:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/社交-Ateen.mp4",
          cover:
            "https://shunyi-1301391939.cos.ap-shanghai.myqcloud.com/index/horizontal/cover/社交-Ateen.jpg",
        },
      ],
      caseList: [
        {
          id: 1,
          title: "电商",
          icon: require("@/assets/images/depthAnalysis/shop.png"),
          activeIcon: require("@/assets/images/depthAnalysis/shop-active.png"),
          caseImg: require("@/assets/images/depthAnalysis/case1.png"),
          child: [
            {
              id: 11,
              name: "「 行业痛点 」",
              desc: ["电商投放要求数据高度敏感，投放趋势要把控及时"],
            },
            {
              id: 12,
              name: "「 闪投方案 」",
              desc: [
                "小时级投放操作，根据转化节点精准铺量",
                "通过不同的定向、文案触及精准人群",
              ],
            },
            {
              id: 13,
              name: "「 达成效果 」",
              desc: [
                "分钟级快速铺量，提升消耗的同时延长计划生命周期",
                "产投分析数据维度精细，快速定位跑量原因",
              ],
            },
          ],
        },
        {
          id: 2,
          title: "工具",
          icon: require("@/assets/images/depthAnalysis/tool.png"),
          activeIcon: require("@/assets/images/depthAnalysis/tool-active.png"),
          caseImg: require("@/assets/images/depthAnalysis/case2.png"),
          child: [
            {
              id: 21,
              name: "「 行业痛点 」",
              desc: [
                "需要程序化搭建大批量测试素材，配合差异化定向及自动管理预警能力，做到流量覆盖",
              ],
            },
            {
              id: 22,
              name: "「 闪投方案 」",
              desc: [
                "一键复制已有广告模型可实现大规模素材测试；减少耗时耗力的人工搭建，实现自动化",
                "多维度数据报表整合实现投放闭环，精准抓住起量内因",
              ],
            },
            {
              id: 23,
              name: "「 达成效果 」",
              desc: [
                "账户X素材X定向=5分钟搭建N账户 1000+丰富计划",
                "资产聚合管理：定向、落地页、文案、人群包快速选取，同产品下无需推送即可使用",
                "区分产品、媒体、账户、计划等维度报表，数据指导投放的同时，总览全局",
              ],
            },
          ],
        },
        {
          id: 3,
          title: "游戏",
          icon: require("@/assets/images/depthAnalysis/game.png"),
          activeIcon: require("@/assets/images/depthAnalysis/game-active.png"),
          caseImg: require("@/assets/images/depthAnalysis/case3.png"),
          child: [
            {
              id: 31,
              name: "「 行业痛点 」",
              desc: [
                "适应行业竞争特性，广告投放平台多、账户多、定向精细化等特点",
                "支持资产线上同一管理，转化追踪、试玩素材跨账户推送",
              ],
            },
            {
              id: 32,
              name: "「 闪投方案 」",
              desc: [
                "支持多账户批量创建，丰富的匹配规则适应不同的搭建需求",
                "客户端支持跨账户跨平台管理、一键切换，批量管理",
              ],
            },
            {
              id: 33,
              name: "「 达成效果 」",
              desc: [
                "7种变量X2个规则X3种匹配，实现精细化的投放",
                "8类资产上线统一管理，跨平台批量管理3倍提效",
                "3大主流媒体一站式管理，一键批量修改",
              ],
            },
          ],
        },
        {
          id: 4,
          title: "小说",
          icon: require("@/assets/images/depthAnalysis/novel.png"),
          activeIcon: require("@/assets/images/depthAnalysis/novel-active.png"),
          caseImg: require("@/assets/images/depthAnalysis/case4.png"),
          child: [
            {
              id: 41,
              name: "「 行业痛点 」",
              desc: [
                "转化追踪分配严格，不同类目出价差异化大",
                "对投放人群、时段、账户结构要求较高",
              ],
            },
            {
              id: 42,
              name: "「 闪投方案 」",
              desc: [
                "监测活动可按照投放需求配置规则，并分时分批提交广告计划",
                "账户搭建结构可视化、减少人工操作成本",
              ],
            },
            {
              id: 43,
              name: "「 达成效果 」",
              desc: [
                "监测活动批量上传+转化跟踪跨账户推送，不再来回找手机联调",
                "模型支持固定出价、阶梯出价、随机价格区间，冷启动通过率提升50%",
                "定时提交、分配定时提交，触达流量高峰",
              ],
            },
          ],
        },
        {
          id: 5,
          title: "教育",
          icon: require("@/assets/images/depthAnalysis/edu.png"),
          activeIcon: require("@/assets/images/depthAnalysis/edu-active.png"),
          caseImg: require("@/assets/images/depthAnalysis/case5.png"),
          child: [
            {
              id: 51,
              name: "「 行业痛点 」",
              desc: [
                "客单价高、成本压力大，销售线索需要全链路监控，提升线索有效性",
              ],
            },
            {
              id: 52,
              name: "「 闪投方案 」",
              desc: [
                "平台统一管理落地页、事件管理等资产；支持投放变量与资产多样性搭配",
                "定制化时报、日报及时发送帮助有效盯盘，预警与智能调控减少账户空耗",
              ],
            },
            {
              id: 53,
              name: "「 达成效果 」",
              desc: [
                "投放报表跨平台多维度智能生成，告别繁琐制表",
                "智能预警24小时盯盘，5分钟系统巡检",
                "起量因子结合监测匹配，洞察群人特性",
              ],
            },
          ],
        },
      ],
    };
  },
  watch: {
    cusActiveIdx(newV) {
      if (newV) {
        // this.handleAllPlay();
        this.handleVideoPlay(newV);
        // this.videoElement[0].autoplay = false;
        // this.videoElement[this.curVideoList.length - 1].autoplay = false;
        // this.videoElement[0].pause();
        // this.videoElement[this.curVideoList.length - 1].pause();
      }
    },
  },
  methods: {
    // 控制哪条视频播放
    handleVideoPlay(index, direction) {
      // console.log(index);
      if (direction === "right" && index === 0) return;
      let video = this.videoElement[index];
      video.play();
    },
    handleBtnClickVideoPrev() {
      this.transIndex--;
      // this.handleCurShowVideo(true);
      this.transWidth = this.transIndex * 124;
      this.cusActiveIdx = this.transIndex;
      this.handleAllPlay();
      this.handleVideoPlay(this.transIndex);
    },
    // 初始化播放第一个
    handlePlay() {
      this.videoElement = this.$refs.videoView;
      if (this.videoElement && this.videoElement.length) {
        for (let i = 0; i < this.videoElement.length; i++)
          if (i === 0) {
            this.videoElement[i].autoplay = true;
          }
      }
    },
    handleBtnClickVideoNext() {
      this.transIndex++;
      // this.handleCurShowVideo(false);
      // if(cusActiveIdx){

      // }
      this.transWidth = this.transIndex * 124;
      // this.cusActiveIdx = this.transIndex;
      if (this.transIndex === 0) return;
      this.handleAllPlay();
      let index = this.cusActiveIdx || this.transIndex;
      this.cusActiveIdx = this.cusActiveIdx + 1;
      this.handleVideoPlay(index + 1);
    },
    //控制只播放当前视频
    handleAllPlay() {
      if (this.videoElement && this.videoElement.length) {
        for (let i = 0; i < this.videoElement.length; i++) {
          let item = this.videoElement[i];
          if (item) {
            item.autoplay = false;
            item.pause();
          }
        }
      }
    },
    handleVideoMuted() {
      this.muted = !this.muted;
    },
    cusHover(item, index) {
      this.cusActiveIdx = index;
      this.handleAllPlay();
      this.handleVideoPlay(this.cusActiveIdx);
      this.curShowMutedBtnIdx = index;
    },
  },
  async mounted() {
    this.transIndex2 = this.customVideoList.length;
    await this.handlePlay();
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/stylesheets/depthanalysis.less";
.page {
  .page-top {
    background-image: url("../../assets/images/depthAnalysis/online1.png");
  }
  .item-con {
    justify-content: space-between;
    padding: 0 66px 0 75px;
    .item-left {
      width: auto;
    }
  }
}
.customer {
  .customer-title {
    margin-bottom: 40px;
  }
  .tab-box {
    text-align: left;
    margin-bottom: 20px;
    img {
      vertical-align: middle;
      margin-top: -3px;
    }
  }
  /deep/ .ivu-radio {
    display: none;
  }

  /deep/ .ivu-radio-wrapper-checked {
    border-bottom: 3px solid #3519fb;
  }
  /deep/ .ivu-radio-group-item {
    margin-right: 70px;
    font-size: 20px;
    height: 45px;
    line-height: 45px;
    span {
      margin-left: 18px;
    }
  }
  .radio-checked {
    color: #453dfe;
    font-size: 24px;
    line-height: 33px;
    font-weight: 600;
    margin-left: 14px;
  }
}

.item-left {
  display: flex;
  dl + dl {
    margin-left: 30px;
  }
  dt {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    line-height: 22px;
    margin-bottom: 34px;
  }
  dd {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
    line-height: 20px;
    margin-top: 10px;
  }
  &.custom-left {
    flex-direction: column;
    dl + dl {
      margin-left: 0;
    }
    .custom-dl {
      margin-bottom: 30px;
      dt {
        margin-bottom: 16px;
      }
      dd {
        white-space: pre-line;
      }
    }
  }
}
.case-bg1 {
  background: url("../../assets/images/depthAnalysis/online3.png") no-repeat;
  height: 476px;
  background-size: contain;
  position: relative;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 30px;
  font-size: 16px;
  padding: 16px 30px 30px;
  text-align: left;
  .case-title {
    color: #ffffff;
    line-height: 28px;
    font-size: 20px;
    font-weight: 600;
  }
  .case-box {
    height: 386px;
    background: #ffffff;
    margin-top: 16px;
    position: relative;
  }
  .case-content {
    display: flex;
    justify-content: center;
    dl {
      width: 286px;
      margin-top: 30px;
    }
    dl + dl {
      margin-left: 30px;
    }
    dt {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      line-height: 22px;
      margin-bottom: 15px;
    }
    dd {
      display: inline-flex;
      align-items: baseline;
      span {
        margin-left: 10px;
      }
    }
  }
  .cooperation {
    position: absolute;
    bottom: 30px;
    left: 30px;
    p {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
      line-height: 30px;
      font-size: 14px;
      margin-bottom: 16px;
    }
  }
}
.case-bg {
  background: url("../../assets/images/depthAnalysis/online5.png") no-repeat;
  height: 428px;
  background-size: contain;
  position: relative;
  .case-con {
    width: 628px;
    height: 348px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    right: 50px;
    top: 40px;
    padding: 0 40px 0 30px;
    p {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.7);
      line-height: 32px;
      font-size: 16px;
      text-align: left;
      display: inline-flex;
      align-items: baseline;
      span {
        margin-left: 20px;
      }
    }
  }
  .case-title {
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    line-height: 28px;
    margin-bottom: 26px;
  }
}
.custom-wrap {
  text-align: center;

  .custom {
    position: relative;
    overflow-x: hidden;
    white-space: nowrap;

    .custom-btn {
      width: 25px;
      height: 37px;
      position: absolute;
      top: 50%;
      z-index: 100;
      transform: translateY(-50%);
      background-image: url("../../assets/images/depthAnalysis/single-left.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .custom-left-btn {
      left: 10px;
      background-image: url("../../assets/images/depthAnalysis/single-left.png");
      &:hover {
        background-image: url("../../assets/images/depthAnalysis/single-left-hover.png");
      }
    }
    .custom-right-btn {
      right: 10px;
      background-image: url("../../assets/images/depthAnalysis/single-right.png");
      &:hover {
        background-image: url("../../assets/images/depthAnalysis/single-right-hover.png");
      }
    }
    .custom-item {
      display: inline-block;
      position: relative;
      margin-right: 5px;
      border-radius: 6px;
      transition: all 0.3s;
      .custom-video {
        object-fit: fill;
        object-fit: cover;
        width: 119px;
        height: 378px;
        border-radius: 6px;
        transition: width 0.4s ease-out, opacity 0.2s ease-in,
          visibility 0.2s ease-in;
      }
      &.customActive {
        .custom-video {
          opacity: 1;
          visibility: 1;
          width: 582px;
        }
      }
      .voice-btn {
        width: 36px;
        height: 36px;
        background: rgba(0, 0, 0, 0.57);
        border-radius: 50%;
        z-index: 100;
        cursor: pointer;
        position: absolute;
        right: 20px;
        bottom: 20px;
        img {
          width: 18px;
          height: 18px;
          position: absolute;
          transform: translate(-50%, 50%);
        }
      }
    }
  }

  @-webkit-keyframes mymove {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(-3200px, 0);
    }
  }
  @keyframes mymove {
    from {
      transform: translate(0, 0);
    }

    to {
      transform: translate(-3200px, 0);
    }
  }
  .industry-wrap {
    overflow-x: hidden;
    white-space: nowrap;
    position: relative;

    .rolling {
      display: inline-block;

      width: 3190px;

      transform: translateX(40%);
      animation: 25s mymove linear 1s infinite normal;

      &:last-child {
        margin-left: -5px;
      }
      &:hover {
        // animation-play-state: paused;
      }
    }
    .industry {
      display: inline-block;
      width: 270px;
      height: 174px;
      border-radius: 6px;
      position: relative;
      margin-right: 20px;

      .industry-icon {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
      .industry-name {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        text-align: center;
        border-radius: 0 0 6px 6px;
        font-weight: 600;
        font-size: 20px;
        color: #fff;
        height: 48px;
        line-height: 48px;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
      }
    }
  }

  .custom-info {
    display: flex;
    justify-content: space-between;
    // .custom-introduce {
    //   position: relative;
    //   overflow-x: hidden;
    // }
    .custom-introduce {
      width: 283px;
      text-align: center;
      padding: 20px;
      .custom-icon {
        width: 162px;
        height: 162px;
        margin-bottom: 16px;
      }
      .custom-name {
        font-weight: 600;
        color: #333333;
        line-height: 33px;
        font-size: 24px;
        margin-bottom: 20px;
      }
      .custom-info {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
      }
    }
  }
}
.three-title {
  color: #453dfe;
  text-align: center;
  line-height: 28px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 32px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mt0 {
  margin-top: 0;
}
</style>
