var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "menu-side-box" }, [
    _c(
      "div",
      {
        staticClass: "menu-view-box",
        style: {
          width: _vm.isOpen ? "200px" : "74px",
        },
      },
      [
        _c(
          "Menu",
          {
            ref: "menu",
            staticClass: "menu-box",
            class: _vm.isOpen ? "menu-maximize-box" : "menu-minimize-box",
            attrs: {
              theme: "light",
              width: _vm.isOpen ? "200px" : "74px",
              "active-name": _vm.activeMenu,
              "open-names": _vm.openNames,
            },
            on: { "on-select": _vm.onSelect },
          },
          _vm._l(_vm.menuList, function (item) {
            return _c(
              "div",
              { key: item.key },
              [
                item.children && item.children.length
                  ? _c(
                      "Submenu",
                      { attrs: { name: item.key } },
                      [
                        _c(
                          "template",
                          { slot: "title" },
                          [
                            _c(
                              "transition",
                              { attrs: { name: "zoom", mode: "out-in" } },
                              [
                                _vm.isOpen
                                  ? _c(
                                      "span",
                                      { staticClass: "menu-sub-title" },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "menu-sub-title" },
                                      [_vm._v(_vm._s(item.title))]
                                    ),
                              ]
                            ),
                          ],
                          1
                        ),
                        item.children && item.children.length
                          ? _vm._l(item.children, function (child) {
                              return _c(
                                "MenuItem",
                                { key: child.key, attrs: { name: child.key } },
                                [
                                  _c("img", {
                                    staticClass: "menu-item-icon",
                                    attrs: { src: child.icon },
                                  }),
                                  _c(
                                    "transition",
                                    { attrs: { name: "zoom", mode: "out-in" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.isOpen,
                                              expression: "isOpen",
                                            },
                                          ],
                                          staticClass: "menu-item-title",
                                        },
                                        [_vm._v(_vm._s(child.name))]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            })
                          : _vm._e(),
                      ],
                      2
                    )
                  : _c(
                      "MenuItem",
                      {
                        staticClass: "menu-sub-nochild",
                        attrs: { name: item.key },
                      },
                      [
                        _c(
                          "transition",
                          { attrs: { name: "zoom", mode: "out-in" } },
                          [
                            _vm.isOpen
                              ? _c("span", { staticClass: "menu-sub-title" }, [
                                  _vm._v(_vm._s(item.name)),
                                ])
                              : _c("span", { staticClass: "menu-sub-title" }, [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                          ]
                        ),
                      ],
                      1
                    ),
              ],
              1
            )
          }),
          0
        ),
      ],
      1
    ),
    _c("i", {
      staticClass: "icon-box",
      class: _vm.isOpen ? "" : "icon-box-up",
      style: { left: _vm.isOpen ? "180px" : "53px" },
      on: { click: _vm.clickIcon },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }