<template>
  <div class="summary-cards" @click="cardsClick">
    <div class="logo">
      <div class="logo-size" v-if="!logoName">
        <img :src="logo" v-if="logo" />
        <template v-else>
          <span class="pic-name text-ellipsis2 center-middle-box">{{ title }}</span>
          <img src="@/assets/images/business/logobg2.png" alt="">
        </template>
      </div>
      <div class="logo-box" v-if="logoName">
        <img src="@/assets/images/logo_mask.png" alt="" />
        <div class="logo-name">{{ logoName }}</div>
      </div>
    </div>
    <div class="introduction">
      <div class="introduction-box">
        <span class="title">{{ title }}</span>
        <span class="type-tab" v-if="type">{{ type }}</span>
        <template v-if="isStoreDetail">
          <a
            class="introduction-back"
            href="javascript:void(0);"
            @click="goPrevBack"
            >查看品牌</a
          >
        </template>
        <div class="btn-size" :class="this.cardType === 2 ? 'btn-size2' : ''">
          <div
            class="btn-box"
            :class="isCollect ? 'active' : ''"
            v-if="collectShow"
            @click="collect"
          >
            <img
              src="@/assets/images/icon/collect.png"
              class="btn-icon"
              v-if="isCollect"
            />
            <img
              src="@/assets/images/icon/collect_hover.png"
              class="btn-icon"
              v-if="!isCollect"
            />
            <span v-if="!isCollect">收藏</span>
            <span v-if="isCollect">已收藏</span>
          </div>
          <BrandClaim
            v-if="this.cardType === 1 || this.cardType === 2"
            :auto="true"
            :propsData="brandClaimData"
          >
            <template v-slot="slot_scoped">
              <div
                class="btn-box"
                :class="slot_scoped.data.cliamStatus === 3 ? 'active' : ''"
                v-if="claimShow"
              >
                <img
                  src="@/assets/images/icon/claim.png"
                  class="btn-icon"
                  v-if="slot_scoped.data.cliamStatus === 3"
                />
                <img
                  src="@/assets/images/icon/claim_hover.png"
                  class="btn-icon"
                  v-else
                />
                认领
              </div>
            </template>
          </BrandClaim>
          <div class="btn-box" v-if="shareShow" @click.stop="shareClick">
            <!-- <img src="@/assets/images/icon/share.png" class="btn-icon" v-if="isShare"> -->
            <img src="@/assets/images/icon/share_hover.png" class="btn-icon" />
            分享
          </div>
          <!--分享弹窗-->
          <div class="share-modal" v-if="shareModalCon">
            <div id="triangle-up"></div>
            <div
              class="share-copy"
              v-clipboard:copy="copyLink"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
              @click.stop="copyClick"
            >
              <img src="@/assets/images/icon/copy_icon.png" alt="" />
              复制小程序链接
            </div>
            <div class="share-line"></div>
            <div class="share-main">
              <div class="code-size">
                <!-- 四个角的边框效果 -->
                <div class="border_corner border_corner_left_top"></div>
                <div class="border_corner border_corner_right_top"></div>
                <div class="border_corner border_corner_left_bottom"></div>
                <div class="border_corner border_corner_right_bottom"></div>
                <!--二维码-->
                <div class="code-box">
                  <img :src="shareCodeImg" alt="" />
                </div>
              </div>
            </div>
            <div class="share-word">「 扫码使用千里目小程序 」</div>
          </div>
        </div>
        <DatePicker
          v-if="isShowDate"
          class="header-date-serch"
          type="daterange"
          placeholder="按日期范围筛选"
          :options="dateOptions"
          placement="bottom-end"
          separator=" 至 "
          style="width: 240px"
          @on-change="changeDaterange"
        ></DatePicker>
      </div>
      <div class="introduction-box">
        <span class="content" v-if="company"
          >所属公司：<b>{{ company }}</b>
          <span v-if="isShowGsxx" class="gsxx-btn" @click="onSelect()">（工商信息）</span>
          </span
        >
        <span class="content" v-if="years"
          >成立时间：<b>{{ years }}</b></span
        >
        <span class="content" v-if="pattern"
          >经营模式：<b>{{ pattern }}</b></span
        >

        <span class="content" v-if="level"
          >商圈级别：<b>{{ level }}</b></span
        >
        <span class="content" v-if="grade"
          >商圈等级：<b>{{ grade }}</b></span
        >
        <span class="content" v-if="occupied_area"
          >占有面积：<b>{{ occupied_area }}km²</b></span
        >
        <span class="content" v-if="city"
          >所在城市：<b>{{ city }}</b></span
        >

        <span class="content" v-if="phone"
          >电话：<b>{{ phone }}</b></span
        >
        <span class="content" v-if="address"
          >地址：<b>{{ address }}</b></span
        >
        <span class="content" v-if="developer"
          >开发商：<b>{{ developer }}</b></span
        >

        <span class="content" v-if="cityGrade"
          >城市级别：<b>{{ cityGrade }}</b></span
        >
        <span class="content" v-if="cityLevel"
          >城市线级：<b>{{ cityLevel }}</b></span
        >
      </div>
    </div>
    <div class="data-list">
      <div
        class="list-item"
        v-for="(item, index) in listData"
        :key="'list_' + index"
      >
        <div class="item-name">{{ item.name }}</div>
        <!-- v-if="(isVip || item.vipData) " -->
        <div class="item-num" >
          <template v-if="!isShowDate && cardType == 2">
            --<span class="item-unit">{{ item.unit }}</span>
          </template>
          <template v-if="isShowDate && cardType == 2">
            {{ item.num }}<span class="item-unit">{{ item.unit }}</span>
          </template>
          <template v-if="!item.vipData && !item.sVipData && cardType !== 2" >
            {{ item.num }}<span class="item-unit">{{ item.unit }}</span>
          </template>
           <template v-if="item.vipData && isVip && cardType !== 2">
            {{ item.num }}<span class="item-unit">{{ item.unit }}</span>
          </template>
          <template v-if="item.sVipData && isSVip && cardType !== 2">
            {{ item.num }}<span class="item-unit">{{ item.unit }}</span>
          </template>
        </div>
        <template v-if="!isVip && item.vipData">
          <template
            v-if="['客群覆盖人口总数', '客群覆盖家庭户数'].includes(item.name)"
          >
            <!-- <img
              src="@/assets/images/icon/base_vip_icon.png"
              class="vip-icon"
              alt=""
            /> -->
            <Icon type="ios-lock-outline"  class="vip-icon"/>
            <div
              class="vip-mask"
              v-if="!isVip && item.vipData"
              @click="vipClick"
            ></div>
          </template>
          <template v-else>
            <!-- <img
              src="@/assets/images/icon/vip_icon.png"
              class="vip-icon"
              alt=""
            /> -->
<Icon type="ios-lock-outline"  class="vip-icon"/>

            <div
              class="vip-mask"
              v-if="!isVip && item.vipData"
              @click="vipClick"
            ></div>
          </template>
        </template>
        <template v-if="!isSVip &&  item.sVipData ">
          <template>
            <!-- <img
              v-if="!['客群覆盖人口总数', '客群覆盖家庭户数'].includes(item.name)"
              src="@/assets/images/icon/vip_icon.png"
              class="vip-icon"
              alt=""
            /> -->
            <Icon v-if="!['客群覆盖人口总数', '客群覆盖家庭户数'].includes(item.name)" type="ios-lock-outline"  class="vip-icon"/>
            <div
              class="vip-mask"
              v-if="!isSVip && item.vipData"
              @click="vipClick"
            ></div>
          </template>
        </template>
      </div>
    </div>
    <div class="city-introduction" v-if="cityIntroduction">
      {{ cityIntroduction }}
    </div>
    <div v-if="isShowTips">
      线上营销统计结果采用全网平台短视频抽样数据，仅供参考。
    </div>
    <ModalUpgrade :isShow="isShow" @close="isShow = false"></ModalUpgrade>
  </div>
</template>

<script>
import ModalUpgrade from "@/components/ModalUpgrade.vue"
import { numberFormat } from "js/utils.js";
import { login } from "js/utils.js";
import { User } from "../service/user";
import BrandClaim from "@/components/brandClaim/BrandClaim.vue";
import axios from "axios";
import { config } from "process";
import { GetShareLink, SaveScene } from "@/service";

export default {
  props: [
    "cardType",
    "cardInfo",
    "statistics",
    "isVip",
    "isSVip",
    "collectShow",
    "claimShow",
    "shareShow",
    "detailID",
    "isShowTips",
    "isShowDate",
    "isShowGsxx"
  ],
  data() {
    return {
      isShow:false,
      listData: [],
      isCollect: false, //是否已收藏
      isClaim: false, //是否已认领

      logo: "",
      title: "",
      type: "",
      company: "", //所在公司
      years: "", //成立时间
      pattern: "", //经营模式
      areaNum: "", //面积要求

      logoName: "",
      level: "", //商圈级别
      grade: "", //商圈等级
      occupied_area: "", //占有面积
      city: "", //所在城市

      phone: "", //电话
      address: "", //地址
      developer: "", //开发商

      cityGrade: "", //城市级别
      cityLevel: "", //城市线级
      cityIntroduction: "", //城市简介

      sort: "",

      shareModalCon: false,
      shareCodeImg: "",
      copyLink: "",

      brandClaimData: {},
      // 日期组件配置
      dateOptions: {
        // 不可选日期范围
        disabledDate(date) {
          return (
            date &&
            (date.valueOf() > Date.now() ||
              date.valueOf() < new Date("2019-12-31"))
          );
        },
        // 快捷选项
        shortcuts: [
          {
            text: "近7天",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "近30天",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "近90天",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            },
          },
        ],
      },
    };
  },
  watch: {
    cardInfo(val) {
      this.getCardType();
      if(val['总人口数'] || val['总家庭户数']){
        /* eslint-disable*/
      // console.log(val,"val")

      this.getCardType();
      }
      if (
        this.cardType === 1 ||
        this.cardType === 3 ||
        this.cardType === 4 ||
        this.cardType === 5 ||
        this.cardType === 6
      ) {
        this.getShareImg();
        this.getShareLink();
      }
    },
    statistics: {
      handler() {
        this.getCardType();
      },
      deep: true,
    },
    cardType(val) {
      this.getCardType();
      //this.getSort();
    },
    detailID() {
      if (this.collectShow) {
        this.checkCollect();
      }
    },
    isShowDate(newVal) {
      if (!newVal) {
        this.changeDaterange([]);
      }
    },
    isShowGsxx(val){
      console.log(val)
    }
  },
  computed: {
    isUserLogin() {
      return this.$store.state.isUserLogin;
    },
    isStoreDetail() {
      return location.pathname == "/storeDetail";
    },
  },
  mounted() {
    this.getCardType();
    if (this.collectShow) {
      if (this.cardType === 1) {
        //品牌详情
        this.sort = this.$store.getters.getSortId("brand");
        this.$set(this.brandClaimData, "brandName", this.company);
        this.$set(this.brandClaimData, "brandID", this.$route.query.id);
      } else if (this.cardType === 3) {
        //商圈
        this.sort = this.$store.getters.getSortId("business");
      } else if (this.cardType === 4) {
        //商场
        this.sort = this.$store.getters.getSortId("mall");
      } else if (this.cardType === 5) {
        //城市
        this.sort = this.$store.getters.getSortId("city");
      }
      this.checkCollect();
    }

    if (
      this.cardType === 1 ||
      this.cardType === 3 ||
      this.cardType === 4 ||
      this.cardType === 5 ||
      this.cardType === 6
    ) {
      this.getShareImg();
      this.getShareLink();
    }
  },
  methods: {
    // 日期值变化事件
    changeDaterange(date) {
      this.$emit("changeDaterange", date);
    },
    goPrevBack() {
      const { brandID } = this.$route.query;
      this.$router.push({ path: "/brandDetail", query: { id: brandID } });
    },
    getCardType() {
       /* eslint-disable*/
      console.log(this.cardInfo,"this.cardInfo")
      if (this.cardType === 1 || this.cardType === 2) {
        //品牌详情
        this.logo = this.cardInfo[4] || "";
        this.title = this.cardInfo[0] || "--";
        this.type = this.cardInfo[6] || "";
        this.company = this.cardInfo[5] || "--";
        this.years = this.cardInfo[8] || "--";
        this.pattern = this.cardInfo[7] || "--";
        this.areaNum = this.cardInfo[1]
          ? this.cardInfo[1].substring(0, this.cardInfo[1].length - 2) || 0
          : "";
        if (this.cardType === 1) {
          this.listData = [
            {
              name: "在营门店",
              num: this.cardInfo[12] || 0,
              unit: "家",
              vipData: false,
            },
            {
              name: "覆盖省数量",
              num: this.cardInfo[10] || 0,
              unit: "个",
              vipData: false,
            },
            {
              name: "覆盖城市数量",
              num: this.cardInfo[11] || 0,
              unit: "个",
              vipData: false,
            },
            {
              name: "面积要求",
              num: this.areaNum || "--",
              unit: "m²",
              vipData: false,
            },
            {
              name: "入驻购物中心",
              num: this.cardInfo[13] || 0,
              unit: "家",
              vipData: false,
            },
            {
              name: "人均消费",
              num: this.cardInfo[3] || "--",
              unit: "元",
              vipData: false,
            },
            { name: "客群覆盖人口总数", num: this.cardInfo[20], unit: "个", vipData: true },
            { name: "客群覆盖家庭户数", num: this.cardInfo[21], unit: "个", vipData: true },
            { name: "门店附近居住人口", num: "0", unit: "个", sVipData: true },
            { name: "门店附近工作人口", num: "0", unit: "个",sVipData: true },
            { name: "门店到访", num: "0", unit: "个", sVipData: true },
          ];
        } else if (this.cardType === 2) {
          this.listData = [
            {
              name: "创意投放",
              num: numberFormat(this.statistics.putNum, 0, true) || "--",
              unit: "个",
              vipData: false,
            },
            {
              name: "创意曝光",
              num: numberFormat(this.statistics.exposureNum, 0, true) || "--",
              unit: "次",
              vipData: false,
            },
            {
              name: "创意点击",
              num: numberFormat(this.statistics.clickNum, 0, true) || "--",
              unit: "次",
              vipData: false,
            },
          ];
        }
      } else if (this.cardType === 3) {
        //商圈
        this.logoName = this.cardInfo[6] || "";
        this.title = this.cardInfo[6] || "--";
        this.level = this.cardInfo[4] || "--";
        this.grade = this.cardInfo[7] || "--";
        this.occupied_area = this.cardInfo[0] || "--";
        this.city = this.cardInfo[2] || "--";
        this.listData = [
          {
            name: "入驻品牌",
            num: this.cardInfo[1] || "0",
            unit: "家",
            vipData: false,
          },
          {
            name: "人口规模",
            num: this.cardInfo[13] || "0",
            unit: "人",
            vipData: false,
          },
          {
            name: "商业项目",
            num: this.cardInfo[15] || "0",
            unit: "个",
            vipData: false,
          },
          {
            name: "人均购物中心面积",
            num: Number(this.cardInfo[8]).toFixed(2) || "--",
            unit: "m²",
            vipData: false,
          },
          {
            name: "小区",
            num: this.cardInfo[16] || "0",
            unit: "个",
            vipData: false,
          },
          {
            name: "写字楼",
            num: this.cardInfo[12] || "0",
            unit: "个",
            vipData: false,
          },
          {
            name: "学校",
            num: this.cardInfo[14] || "0",
            unit: "个",
            vipData: false,
          },
          {
            name: "公司企业",
            num: this.cardInfo[11] || "0",
            unit: "个",
            vipData: false,
          },
          {
            name: "医院",
            num: this.cardInfo[17] || "0",
            unit: "家",
            vipData: false,
          },
          {
            name: "公共交通",
            num: this.cardInfo[10] || "0",
            unit: "个",
            vipData: false,
          },
        ];
      } else if (this.cardType === 4) {
        //商场
        this.logo = this.cardInfo["图片"] || "";
        this.title = this.cardInfo["名称"] || "--";
        this.phone = this.cardInfo["电话"] || "--";
        this.address = this.cardInfo["地址"] || "--";
        this.developer = this.cardInfo["开发商"] || "--";
        this.listData = [
          {
            name: "开业时间",
            num: this.cardInfo["开业时间"] || "--",
            unit: "",
            vipData: false,
          },
          {
            name: "经营年限",
            num: this.cardInfo["经营年限"] || "--",
            unit: "年",
            vipData: false,
          },
          {
            name: "商业面积",
            num: this.cardInfo["商业面积"] || "--",
            unit: "m²",
            vipData: false,
          },
          {
            name: "商户数",
            num: this.cardInfo["商户数"] || "--",
            unit: "个",
            vipData: false,
          },
          {
            name: "楼层数",
            num: this.cardInfo["楼层数"] || "--",
            unit: "",
            vipData: false,
          },
          {
            name: "发展阶段",
            num: this.cardInfo["发展阶段"] || "--",
            unit: "",
            vipData: false,
          },
          {
            name: "停车位",
            num: this.cardInfo["停车位"] || "--",
            unit: "个",
            vipData: false,
          },
          {
            name: "商场类型",
            num: this.cardInfo["商场类型"] || "--",
            unit: "",
            vipData: false,
          },
        ];
      } else if (this.cardType === 5) {
        //城市
        this.logo = this.cardInfo["城市图片"] || "";
        this.title = this.cardInfo["城市名称"] || "";
        this.cityGrade = this.cardInfo["城市级别"] || "";
        this.cityLevel = this.cardInfo["城市线级"] || "";
        this.cityIntroduction = this.cardInfo["城市简介"] || "";
        this.listData = [
          {
            name: "城市面积",
            num: this.cardInfo["城市面积"] || "--",
            unit: "km²",
            vipData: false,
          },
          {
            name: "户籍人口",
            num: String(this.cardInfo["户籍人口"] || "--").replace("万人", ""),
            unit: "万人",
            vipData: false,
          },
          {
            name: "常住人口",
            num:
              String(this.cardInfo["常住人口"] || "--").replace("万人", "") ||
              "--",
            unit: "万人",
            vipData: false,
          },
        ];
      } else if (this.cardType === 6) {
        //门店
        this.logo = this.cardInfo["图片url"] || "";
        this.title = this.cardInfo["门店名称"] || "";
        this.company = this.cardInfo["公司名称"] || "--";
        this.address = this.cardInfo["门店地址"] || "--";
        /* eslint-disable*/
      // console.log(this.cardInfo,this.cardInfo["总人口数"],this.cardInfo["总家庭户数"],"this.cardInfo")
        this.listData = [
          { name: "客群覆盖人口总数", num: this.cardInfo["总人口数"] || '0', unit: "个", vipData: true },
          { name: "客群覆盖家庭户数", num: this.cardInfo["总家庭户数"] || '0', unit: "个", vipData: true },
          { name: "门店附近居住人口", num: "0", unit: "个", sVipData: true },
          { name: "门店附近工作人口", num: "0", unit: "个", sVipData: true },
          { name: "门店到访", num: "0", unit: "个", sVipData: true },
          {
            name: "人均消费",
            num: this.cardInfo["人均消费"] || "--",
            unit: "元",
            vipData: false,
          },
        ];
      }
    },

    //点击收藏
    collect() {
      if (this.isCollect) {
        this.delCollect();
      } else {
        this.addCollect();
      }
    },
    // 添加收藏
    async addCollect() {
      if (!this.checkLogin()) {
        return;
      }
      let params = {
        sort: this.sort,
        code: this.detailID,
      };
      let res = await User.addCollection(params);
      if (res && res.code === 200) {
        this.isCollect = true;
        this.$Message.success(res.msg || "收藏成功");
      }
    },
    // 取消收藏
    async delCollect() {
      if (!this.checkLogin()) {
        return;
      }
      this.$global.confirm({
        title: "提示",
        content: "确认要取消收藏吗？",
        ok: async () => {
          let params = {
            sort: this.sort,
            code: this.detailID,
          };
          let res = await User.deleteCollection(params);
          if (res && res.code === 200) {
            this.isCollect = false;
            this.$Message.success(res.msg || "已取消收藏");
          }
        },
      });
    },
    //检测当前品牌是否已收藏
    async checkCollect() {
      if (this.isUserLogin) {
        let codes = [];
        codes.push(this.detailID);
        let res = await User.checkCollectStatus(this.sort, codes);
        if (res && res.code === 200) {
          if (res.data.items[0]) {
            this.isCollect = res.data.items[0].isExist;
          }
        }
      }
    },
    //检测登录
    checkLogin() {
      if (!this.isUserLogin) {
        this.$global.confirm({
          title: "提示",
          content: "请先登录",
          ok: () => {
            login();
          },
        });
        return false;
      }
      return true;
    },

    //获取分享小程序二维码
    async getShareImg() {
      if (!this.detailID) {
        return false;
      }
      let params = {
        scene: "",
        page: "",
        env_version: "release", //要打开的小程序版本。正式版为 release，体验版为 trial，开发版为 develop 默认：release
      };

      if (this.cardType === 1 || this.cardType === 2 || this.cardType === 6) {
        //品牌详情和门店详情
        //let reg1 = new RegExp("-","g");
        //let newDetailID = String(this.detailID).replace(reg1,'');
        let data = {
          param: this.detailID,
        };
        let res = await SaveScene(data);
        if (res && res.code === 200) {
          params.scene = "?qlmId=" + res.data.items + "";
          params.page = "pagesBrand/brand/brandDetail";
        }
      } else if (this.cardType === 3) {
        //商圈详情
        params.scene = "?code=" + this.detailID + "";
        params.page = "pagesBusiness/business/businessDetail";
      } else if (this.cardType === 4) {
        //商场详情
        params.scene = "?code=" + this.detailID + "";
        params.page = "pagesMall/mall/mallDetail";
      } else if (this.cardType === 5) {
        //城市详情
        params.scene = "?code=" + this.detailID + "";
        params.page = "pagesCity/city/cityDetail";
      }

      let that = this;
      axios
        .post(this.$config.serverUrls.getShareImg, params, {
          responseType: "blob",
        })
        .then(function (res) {
          const myBlob = new window.Blob([res], { type: "image/jpeg" });
          const qrUrl = window.URL.createObjectURL(myBlob);
          that.shareCodeImg = qrUrl;
        })
        .catch(function () {
          this.$Message.error("网络错误，请重试");
        });
    },

    //获取分享链接
    async getShareLink() {
      if (!this.detailID) {
        return false;
      }
      let params = {
        page: "",
        query: "",
      };
      if (this.cardType === 1 || this.cardType === 2 || this.cardType === 6) {
        //品牌详情和门店详情
        params.query = "qlmId=" + this.detailID + "";
        params.page = "pagesBrand/brand/brandDetail";
      } else if (this.cardType === 3) {
        //商圈详情
        params.query = "code=" + this.detailID + "";
        params.page = "pagesBusiness/business/businessDetail";
      } else if (this.cardType === 4) {
        //商场详情
        params.query = "code=" + this.detailID + "";
        params.page = "pagesMall/mall/mallDetail";
      } else if (this.cardType === 5) {
        //城市详情
        params.query = "code=" + this.detailID + "";
        params.page = "pagesCity/city/cityDetail";
      }

      let res = await GetShareLink(params);
      if (res && res.code === 200) {
        this.copyLink = res.data.items || "";
      }
    },

    //点击分享
    shareClick() {
      this.shareModalCon = !this.shareModalCon;
    },
    cardsClick() {
      this.shareModalCon = false;
    },

    //复制链接
    copyClick() {},
    copySuccess() {
      this.$Message.success("链接已复制");
    },
    copyError() {
      this.$Message.error("复制失败");
    },

    //开通VIP
    vipClick() {
      this.isShow = false
      // window.open("/vip", "_blank");
    },

    onSelect(){
      this.$emit("onselect", 'gsxx');
    }
  },
  components: {
    BrandClaim,
    ModalUpgrade


  },
};
</script>

<style lang="less">
.header-date-serch {
  position: absolute;
  right: 0;
}
.summary-cards {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 30px 0 30px 40px;
  display: flex;
  flex-wrap: wrap;
  line-height: 1;
  position: relative;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
  .pic-name {
    width: 66px;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 1.5;
  }
  .logo {
    width: 100px;
    height: 100px;
    border-radius: 6px;

    .logo-size {
      width: 100px;
      height: 100px;
      border-radius: 6px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        width: auto;
        height: auto;
        max-width: 100px;
        max-height: 100px;
        border-radius: 6px;
      }
    }
    .logo-size::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      z-index: 9;
    }

    .logo-box {
      width: 100%;
      height: 100%;
      position: relative;

      .logo-name {
        width: 100%;
        height: 100%;
        font-size: 22px;
        font-weight: 600;
        color: #ffffff;
        line-height: 30px;
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }

  .introduction {
    width: calc(100% - 165px);
    height: auto;
    margin: 0 0 20px 25px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .introduction-box {
      position: relative;
    }

    .introduction-box:nth-child(1) {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 12px;
    }

    .introduction-box:nth-child(2) {
      margin-top: 30px;
      line-height: 26px;
    }

    .introduction-back {
      margin-left: 12px;
      font-size: 14px;
      font-weight: 400;
      color: #3519fb;
    }
    .introduction-back:hover {
      color: #5139ff !important;
    }

    .title {
      font-size: 22px;
      font-weight: 500;
      color: #000000;
    }

    .type-tab {
      width: auto;
      height: 22px;
      border-radius: 6px;
      border: 1px solid #b1afc0;
      font-size: 12px;
      color: #848295;
      padding: 0 8px;
      display: flex;
      align-items: center;
      margin: 0 0 0 10px;
    }

    .content {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.8);
      margin-right: 50px;
      line-height: 22px;
    }
  }

  .data-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 10px;

    .list-item {
      width: 150px;
      height: 70px;
      background: #ffffff;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      padding: 12px 0 0 0;
      text-align: center;
      margin: 0 25px 18px 0;
      position: relative;

      .item-name {
        width: 100%;
        font-size: 14px;
        color: #848295;
        padding: 0 0 11px 0;
      }

      .item-num {
        font-size: 18px;
        color: #000000;
        font-weight: bold;
      }

      .item-unit {
        font-size: 12px;
        color: #858197;
        font-weight: normal;
      }

      .vip-icon {
        width: 37px;
        height: 18px;
        position: absolute;
        top: 40px;
        left: 55px;
        color: #000;
        font-size: 22px;
      }

      .vip-mask {
        width: 150px;
        height: 70px;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
      }
    }
  }

  .city-introduction {
    width: calc(100% - 40px);
    height: auto;
    box-sizing: border-box;
    padding: 20px 25px;
    background: #f6f6fa;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
    line-height: 24px;
  }

  .btn-size {
    width: auto;
    position: relative;
    display: flex;
    margin-left: auto;

    .btn-box {
      width: 80px;
      height: 32px;
      border-radius: 6px;
      border: 1px solid rgba(53, 25, 251, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #000000;
      margin: 0 0 0 20px;
      cursor: pointer;

      .btn-icon {
        width: 14px;
        height: 14px;
        margin-right: 3px;
      }
    }

    .active {
      background: #3519fb;
      color: #ffffff;
    }
  }

  .btn-size2 {
    position: relative;
    margin-left: 0;
  }

  .share-modal {
    width: 269px;
    padding-bottom: 18px;
    background: rgba(255, 255, 255, 0.99);
    box-shadow: 0px 2px 17px 6px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(16px);
    position: absolute;
    top: 50px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
    z-index: 10;
    #triangle-up {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 14px solid #ffffff;
      position: absolute;
      top: -12px;
      right: 30px;
    }
    .share-copy {
      width: 165px;
      height: 32px;
      background: rgba(53, 25, 251, 0.06);
      border-radius: 4px;
      margin: 40px 0 0 0;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #3519fb;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        margin: 0 8px 0 18px;
      }
    }
    .share-line {
      width: 210px;
      height: 1px;
      opacity: 0.2;
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
      margin: 20px 0 0 0;
    }
    .share-main {
      width: 180px;
      height: 180px;
      background: rgba(53, 25, 251, 0.04);
      backdrop-filter: blur(1.3px);
      margin: 20px 0 0 0;
      position: relative;
      .code-size {
        width: calc(100% - 14px);
        height: calc(100% - 14px);
        position: relative;
        margin: 7px;
      }
      .border_corner {
        position: absolute;
        width: 8px;
        height: 8px;
        border: 2px solid #3519fb;
      }
      .border_corner_left_top {
        top: 0;
        left: 0;
        border-right: none;
        border-bottom: none;
        //border-top-left-radius: 4px;
      }
      .border_corner_right_top {
        top: 0;
        right: 0;
        border-left: none;
        border-bottom: none;
        //border-top-right-radius: 4px;
      }
      .border_corner_left_bottom {
        bottom: 0;
        left: 0;
        border-right: none;
        border-top: none;
        //border-bottom-left-radius: 4px;
      }
      .border_corner_right_bottom {
        bottom: 0;
        right: 0;
        border-left: none;
        border-top: none;
        //border-bottom-right-radius: 4px;
      }
      .code-box {
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        position: absolute;
        top: 6px;
        left: 6px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .share-word {
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
      line-height: 1;
      margin-top: 18px;
    }
  }
}
.gsxx-btn{
  cursor: pointer;
  color: #3519fb;
}
</style>
