var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("MapPanel", {
    ref: "mapPanel",
    attrs: { id: _vm.id, ignoreFitChina: true },
    on: { loaded: _vm.handleMapLoaded, click: _vm.handleMapClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }