<template>
  <MapPanel
    ref="mapPanel"
    :id="id"
    :ignoreFitChina="true"
    @loaded="handleMapLoaded"
    @click="handleMapClick"
  ></MapPanel>
</template>

<script>
import MapPanel from "./MapPanel.vue";
import InfoWindowBrand from "@/components/InfoWindowBrand.vue";
import Vue from "vue";
import { GetDetailBrowsingRecord } from "@/service";
import { openNewWindow } from "@/assets/javascript/utils.js";
export default {
  props: ["id", "markers", "polygons", "fitBoundsFeatures", "isClick"],
  data() {
    return {
      mapPanel: null,
    };
  },
  watch: {
    markers: {
      handler() {
        this.initData();
      },
      deep: true,
    },
    polygons: {
      handler() {
        this.initData();
      },
      deep: true,
    },
  },
  methods: {
    initData() {
      let fitFeatures = [];
      if (this.markers) {
        let features = this.markers;
        let options = {
          icon: "/images/marker_blue2.png",
          iconSize: [22, 33],
          anchor: "bottom-center",
        };
        this.mapPanel.setMarkers("markers", features, options);

        fitFeatures = features;
      }
      if (this.polygons) {
        let features = this.polygons;
        let options = {};
        this.mapPanel.setPolygons("polygons", features, options);
        fitFeatures = features;
      }
      if (this.fitBoundsFeatures) {
        fitFeatures = this.fitBoundsFeatures;
      }
      if (this.fitBoundsFeatures || fitFeatures.length > 0) {
        this.mapPanel.fitBounds(fitFeatures, true, [10, 10, 10, 10]);
      }
    },
    handleMapLoaded() {
      this.initData();
    },
    // 商圈点击
    handleMapClick(feature) {
      if (!feature || !this.isClick) return;
      this.openWindowInfo(feature);
    },
    openWindowInfo(feature) {
      let properties = feature.properties;
      let center = feature.geometry.coordinates;
      let InfoWindow = Vue.extend(InfoWindowBrand);
      let instance = new InfoWindow({
        propsData: {
          title: properties["name"],
          list: [
            `地址: ${properties["addr"]}`,
            `开业时间: ${properties["startTime"] || "--"}`,
          ],
          btnText: "查看详情",
        },
      });
      instance.$on("doSubmit", () => {
        let code = properties.code;
        this.gotoMallDetail(code);
      });
      instance.$mount();
      let content = instance.$el;
      this.mapPanel.openInfoWindow(center, content, {
        offset: [0, -23],
        autoMove: false,
      });
    },
    async gotoMallDetail(code) {
      const res = await GetDetailBrowsingRecord({
        module: "mallDetail",
        detailID: code,
      });
      if (res && res.code == 200 && res.data == "success") {
        openNewWindow("mallDetail", {
          id: code,
        });
      }
    },
  },
  mounted() {
    this.mapPanel = this.$refs.mapPanel;
  },
  components: {
    MapPanel,
  },
};
</script>

<style></style>
