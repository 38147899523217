<template>
  <div class="page">
    <div class="page-top">
      <p class="page-top-title">线下数据分析</p>
      <p class="page-top-con">
        通过产品+数据+服务的矩阵方式，契合不同规模客户的不同需求，<br />为客户提供兼顾价格、效果、时间的最适宜的解决方案
      </p>
      <a class="btn" href="https://www.changjing.com.cn/" target="_blank"
        >了解更多<img src="/images/home/right.png" alt="" />
      </a>
    </div>
    <div class="page-content">
      <div class="item" v-for="item of list" :key="item.id">
        <div class="item-top">
          <img
            class="item-icon"
            height="36"
            width="36"
            :src="item.icon"
            alt=""
          />
          <p>
            <span class="item-title">{{ item.product }}</span>
            <span class="line">|</span>
            <span>{{ item.desc }}</span>
          </p>
          <img class="tool" width="54" height="30" :src="item.number" alt="" />
        </div>
        <div class="item-con" :class="{ rtl: item.id % 2 === 0 }">
          <div class="item-left">
            <p>{{ item.content }}</p>
            <a :href="item.href" target="_blank" class="more-btn"
              >了解更多
              <img src="../../assets/images/depthAnalysis/more.png" alt=""
            /></a>
          </div>
          <div class="item-right">
            <img width="438" height="268" :src="item.img" alt="" />
          </div>
        </div>
      </div>
      <section class="customer">
        <div class="customer-title">我们的客户</div>
        <p class="desc">
          众多行业，涵盖零售，地产、金融、广告传媒、交通出行、物流快递、金融保险、<br />城市和政府等行业数百家企业与政府的共同选择
        </p>
        <img
          width="1002"
          height="245"
          src="../../assets/images/depthAnalysis/customer-logo.png"
          alt=""
        />
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "offlineAnalysis",
  data() {
    return {
      list: [
        {
          id: 1,
          product: "亿景智图",
          desc: "创新型企业级地理信息SaaS平台，提供基于时空大数据的位置智能服务",
          content:
            "专业易用地理可视化平台，10分钟打造位置资产“一张图”，实现全景可视化管理。Excel一键导入成图，资产分布一目了然。资产状态分类可视，状态变更实时预警。运维人员科学调度，经营数据多维度分析。",
          href: "https://zt.changjing.com.cn/",
          icon: require("../../assets/images/depthAnalysis/offline-icon1.png"),
          number: require("../../assets/images/depthAnalysis/01.png"),
          img: require("../../assets/images/depthAnalysis/offline2.png"),
        },
        {
          id: 2,
          product: "门店选址",
          desc: "利用时空大数据与机器学习打造的智能化选址决策平台",
          content:
            "通过全面的客群和环境分析来解决店铺开在哪的问题，平台包含海量的城市人口商业地理数据，通过AI构建贴合客户个性化需求的选址模型，一键推荐潜力区域，并对目标点位精准评估，降低选址成本，提升店铺选址的成功率。",
          href: "https://xz.changjing.com.cn/",
          icon: require("../../assets/images/depthAnalysis/offline-icon2.png"),
          number: require("../../assets/images/depthAnalysis/02.png"),
          img: require("../../assets/images/depthAnalysis/offline3.png"),
        },
        {
          id: 3,
          product: "门店洞察",
          desc: "让每个门店都具备专业的客群洞察能力，助力企业创新发展",
          content:
            "为企业定位门店目标客户群体，深度刻画用户画像，辅助客户在市场洞察、经营提升、精准营销等方面作出更科学、更准确、更高效的决策。",
          href: "https://zk.changjing.com.cn/",
          icon: require("../../assets/images/depthAnalysis/offline-icon3.png"),
          number: require("../../assets/images/depthAnalysis/03.png"),
          img: require("../../assets/images/depthAnalysis/offline4.png"),
        },
        {
          id: 4,
          product: "亿景排班",
          desc: "用最短的时间遇见最大可能!  时空大数据和人工智能的外勤排班管理系统",
          content:
            "ASE（AI Schedule Engine）智能排班引擎，自动迭代优化路线模型，动态规划线路，合理配置资源。\n" +
            "\n" +
            "将传统表格排班方式可视化，使操作更加简洁同时提高信息整合程度，高效合理统筹排班计划及路线建议。",
          href: "https://pb.changjing.com.cn/",
          icon: require("../../assets/images/depthAnalysis/offline-icon4.png"),
          number: require("../../assets/images/depthAnalysis/04.png"),
          img: require("../../assets/images/depthAnalysis/offline5.png"),
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/stylesheets/depthanalysis.less";
.page-content {
  margin-top: 50px;
  .item-top {
    margin-top: 0;
  }
}
.item-left {
  p {
    white-space: pre-line;
  }
}
</style>
