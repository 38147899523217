var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "page-content" },
      _vm._l(_vm.list, function (item) {
        return _c("div", { key: item.id, staticClass: "item" }, [
          _c("div", { staticClass: "item-top" }, [
            _c("img", {
              staticClass: "item-icon",
              attrs: { height: "36", width: "36", src: item.icon, alt: "" },
            }),
            _c("p", [
              _c("span", { staticClass: "item-title" }, [
                _vm._v(_vm._s(item.product)),
              ]),
              _c("span", { staticClass: "line" }, [_vm._v("|")]),
              _c("span", [_vm._v(_vm._s(item.desc))]),
            ]),
            _c("img", {
              staticClass: "tool",
              attrs: { width: "54", height: "30", src: item.number, alt: "" },
            }),
          ]),
          _c("div", { staticClass: "item-con" }, [
            _c(
              "div",
              {
                staticClass: "item-left",
                class: { "custom-left": item.id === 3 },
              },
              [
                item.id === 1 || item.id === 2
                  ? _vm._l(item.sub, function (subitem) {
                      return _c(
                        "dl",
                        { key: subitem.name },
                        [
                          _c("dt", [_vm._v(_vm._s(subitem.name))]),
                          _vm._l(subitem.child, function (son) {
                            return _c("dd", { key: son }, [
                              _c("img", {
                                attrs: {
                                  width: "12",
                                  height: "12",
                                  src: require("../../assets/images/depthAnalysis/circle.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" " + _vm._s(son) + " "),
                            ])
                          }),
                        ],
                        2
                      )
                    })
                  : _vm._l(item.sub, function (subitem) {
                      return _c(
                        "dl",
                        { key: subitem.title, staticClass: "custom-dl" },
                        [
                          _c("dt", [_vm._v(_vm._s(subitem.title))]),
                          _c("dd", [_vm._v(_vm._s(subitem.desc))]),
                        ]
                      )
                    }),
              ],
              2
            ),
            _c("div", { staticClass: "item-right" }, [
              _c("img", {
                attrs: { width: "438", height: "268", src: item.img, alt: "" },
              }),
            ]),
          ]),
          _c(
            "section",
            { staticClass: "customer" },
            [
              _c("div", { staticClass: "customer-title" }, [
                _vm._v("客户案例"),
              ]),
              item.id === 1
                ? [
                    _c(
                      "div",
                      { staticClass: "tab-box" },
                      [
                        _c(
                          "RadioGroup",
                          {
                            model: {
                              value: _vm.tabindex,
                              callback: function ($$v) {
                                _vm.tabindex = $$v
                              },
                              expression: "tabindex",
                            },
                          },
                          _vm._l(_vm.caseList, function (tab, index) {
                            return _c(
                              "Radio",
                              { key: tab.id, attrs: { label: index } },
                              [
                                _c("img", {
                                  attrs: {
                                    width: "24",
                                    height: "24",
                                    src:
                                      _vm.tabindex === index
                                        ? tab.activeIcon
                                        : tab.icon,
                                    alt: "",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    class: {
                                      "radio-checked": _vm.tabindex === index,
                                    },
                                  },
                                  [_vm._v(_vm._s(tab.title))]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c("section", { staticClass: "case-bg1" }, [
                      _c("div", { staticClass: "case-title" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.caseList[_vm.tabindex].title) +
                            " . 广告场景 "
                        ),
                      ]),
                      _c("div", { staticClass: "case-box" }, [
                        _c(
                          "div",
                          { staticClass: "case-content" },
                          _vm._l(
                            _vm.caseList[_vm.tabindex].child,
                            function (item) {
                              return _c(
                                "dl",
                                { key: item.id },
                                [
                                  _c("dt", [_vm._v(_vm._s(item.name))]),
                                  _vm._l(item.desc, function (sub, index) {
                                    return _c("dd", { key: "sub" + index }, [
                                      _c("img", {
                                        attrs: {
                                          width: "12",
                                          height: "12",
                                          src: require("../../assets/images/depthAnalysis/circle.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("span", [_vm._v(_vm._s(sub))]),
                                    ])
                                  }),
                                ],
                                2
                              )
                            }
                          ),
                          0
                        ),
                        _c("div", { staticClass: "cooperation" }, [
                          _c("p", [_vm._v("合作案例")]),
                          _c("img", {
                            attrs: {
                              width: "442",
                              height: "46",
                              src: _vm.caseList[_vm.tabindex].caseImg,
                              alt: "",
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c(
                      "a",
                      {
                        staticClass: "more-btn",
                        attrs: { href: item.href, target: "_blank" },
                      },
                      [
                        _vm._v("了解更多 "),
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/depthAnalysis/more.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]
                : _vm._e(),
              item.id === 2
                ? [
                    _vm._m(1, true),
                    _c(
                      "a",
                      {
                        staticClass: "more-btn",
                        attrs: { href: item.href, target: "_blank" },
                      },
                      [
                        _vm._v("了解更多 "),
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/depthAnalysis/more.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]
                : _vm._e(),
              item.id === 3
                ? _c("section", [
                    _c(
                      "div",
                      { staticClass: "custom-wrap wow animate__fadeInUp" },
                      [
                        _c("div", { staticClass: "three-title" }, [
                          _vm._v("「 我们制作了500,000+个视频 」"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "custom mb-60" },
                          [
                            _c("div", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.transWidth !== 0,
                                  expression: "transWidth !== 0",
                                },
                              ],
                              staticClass: "custom-left-btn custom-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.handleBtnClickVideoPrev()
                                },
                                mouseenter: function ($event) {
                                  return _vm.handleVideoPlay(_vm.cusActiveIdx)
                                },
                              },
                            }),
                            _c("div", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.transWidth < 900,
                                  expression: "transWidth < 900",
                                },
                              ],
                              staticClass: "custom-right-btn custom-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.handleBtnClickVideoNext()
                                },
                                mouseenter: function ($event) {
                                  return _vm.handleVideoPlay(_vm.cusActiveIdx)
                                },
                              },
                            }),
                            _vm._l(_vm.customVideoList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "custom-item",
                                  class: {
                                    customActive: _vm.cusActiveIdx === index,
                                  },
                                  style: {
                                    transform:
                                      "translateX(-" + _vm.transWidth + "px)",
                                  },
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.cusHover(item, index)
                                    },
                                  },
                                },
                                [
                                  _vm.curShowMutedBtnIdx === index
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "voice-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleVideoMuted(index)
                                            },
                                            mouseenter: function ($event) {
                                              return _vm.handleVideoPlay(index)
                                            },
                                          },
                                        },
                                        [
                                          !_vm.muted
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("../../assets/images/depthAnalysis/open-voice.png"),
                                                  alt: "",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.muted
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("../../assets/images/depthAnalysis/close-voice.png"),
                                                  alt: "",
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "video",
                                    {
                                      ref: "videoView",
                                      refInFor: true,
                                      staticClass: "custom-video",
                                      attrs: {
                                        autopaly:
                                          _vm.cusActiveIdx === index
                                            ? true
                                            : false,
                                        poster: item.cover ? item.cover : "",
                                        loop: "",
                                        onMouseOver: "this.play()",
                                        onMouseOut: "this.pause()",
                                        src: item.videoAddr,
                                      },
                                      domProps: { muted: _vm.muted },
                                    },
                                    [
                                      _c("source", {
                                        attrs: { src: "", type: "video/mp4" },
                                      }),
                                      _c("source", {
                                        attrs: { src: "", type: "video/ogg" },
                                      }),
                                      _c("source", {
                                        attrs: { src: "", type: "video/webm" },
                                      }),
                                      _vm._m(2, true),
                                    ]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                        _c("div", { staticClass: "three-title mb-50" }, [
                          _vm._v(" 「 为垂直行业提供个性化定制方案 」 "),
                        ]),
                        _c("div", { staticClass: "industry-wrap mb-50" }, [
                          _c(
                            "div",
                            { staticClass: "rolling" },
                            _vm._l(_vm.industry, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "industry" },
                                [
                                  _c("img", {
                                    staticClass: "industry-icon",
                                    attrs: { src: item.icon, alt: "" },
                                  }),
                                  _c("div", { staticClass: "industry-name" }, [
                                    _vm._v(" " + _vm._s(item.name) + " "),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "rolling" },
                            _vm._l(_vm.industry, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "industry" },
                                [
                                  _c("img", {
                                    staticClass: "industry-icon",
                                    attrs: { src: item.icon, alt: "" },
                                  }),
                                  _c("div", { staticClass: "industry-name" }, [
                                    _vm._v(" " + _vm._s(item.name) + " "),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "more-btn mt0",
                        attrs: { href: item.href, target: "_blank" },
                      },
                      [
                        _vm._v("了解更多 "),
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/depthAnalysis/more.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-top" }, [
      _c("p", { staticClass: "page-top-title" }, [_vm._v("线上营销分析")]),
      _c("p", { staticClass: "page-top-con" }, [
        _vm._v(" 联合字节跳动、快手、腾讯等主流媒体，为代理商及广告主提供跨媒"),
        _c("br"),
        _vm._v("体短视频营销整体解决方案，助力业绩增长，实现销量暴涨。 "),
      ]),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://www.clipworks.com/", target: "_blank" },
        },
        [
          _vm._v("了解更多"),
          _c("img", { attrs: { src: "/images/home/right.png", alt: "" } }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "case-bg" }, [
      _c("div", { staticClass: "case-con" }, [
        _c("div", { staticClass: "case-title" }, [
          _vm._v(" 全时天地在线、wpp、微盟等众多头部广告代理商及品牌客户 "),
        ]),
        _c("p", [
          _c("img", {
            attrs: {
              width: "12",
              height: "12",
              src: require("../../assets/images/depthAnalysis/circle.png"),
              alt: "",
            },
          }),
          _c("span", [
            _vm._v(
              "提供无限级部门管理，协助公司内部拍摄资源统筹，人员绩效管理。"
            ),
          ]),
        ]),
        _c("p", [
          _c("img", {
            attrs: {
              width: "12",
              height: "12",
              src: require("../../assets/images/depthAnalysis/circle.png"),
              alt: "",
            },
          }),
          _c("span", [
            _vm._v("帮助商视频拍摄流程线上化、精细化，提高拍摄效率。"),
          ]),
        ]),
        _c("p", [
          _c("img", {
            attrs: {
              width: "12",
              height: "12",
              src: require("../../assets/images/depthAnalysis/circle.png"),
              alt: "",
            },
          }),
          _c("span", [
            _vm._v(
              "工作流线上监工，视频/图片制作进度可控信息传达准确，降低运营成本。"
            ),
          ]),
        ]),
        _c("p", [
          _c("img", {
            attrs: {
              width: "12",
              height: "12",
              src: require("../../assets/images/depthAnalysis/circle.png"),
              alt: "",
            },
          }),
          _c("span", [
            _vm._v(
              "跨媒体投放一键触达，抖音、快手、微信及百度等媒体适配上传素材上传等一键批量操作。"
            ),
          ]),
        ]),
        _c("p", [
          _c("img", {
            attrs: {
              width: "12",
              height: "12",
              src: require("../../assets/images/depthAnalysis/circle.png"),
              alt: "",
            },
          }),
          _c("span", [
            _vm._v(
              "全方位可视化数据报表，反馈运营数据，了解实际素材投放效果。"
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("object", { attrs: { data: "", width: "320", height: "240" } }, [
      _c("embed", { attrs: { src: "", width: "320", height: "240" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }