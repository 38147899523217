<template>
  <div class="menu-side-box">
    <div
      class="menu-view-box"
      :style="{
        width: isOpen ? '200px' : '74px',
      }"
    >
      <Menu
        class="menu-box"
        :class="isOpen ? 'menu-maximize-box' : 'menu-minimize-box'"
        ref="menu"
        theme="light"
        :width="isOpen ? '200px' : '74px'"
        :active-name="activeMenu"
        :open-names="openNames"
        @on-select="onSelect"
      >
        <div v-for="item in menuList" :key="item.key">
          <Submenu
            :name="item.key"
            v-if="item.children && item.children.length"
          >
            <template slot="title">
              <transition name="zoom" mode="out-in">
                <span v-if="isOpen" class="menu-sub-title">{{
                  item.name
                }}</span>
                <span v-else class="menu-sub-title">{{ item.title }}</span>
              </transition>
            </template>
            <template v-if="item.children && item.children.length">
              <MenuItem
                v-for="child in item.children"
                :key="child.key"
                :name="child.key"
              >
                <img :src="child.icon" class="menu-item-icon" />
                <transition name="zoom" mode="out-in">
                  <span v-show="isOpen" class="menu-item-title">{{
                    child.name
                  }}</span>
                </transition>
              </MenuItem>
            </template>
          </Submenu>
          <MenuItem class="menu-sub-nochild" :name="item.key" v-else>
            <transition name="zoom" mode="out-in">
              <span v-if="isOpen" class="menu-sub-title">{{ item.name }}</span>
              <span v-else class="menu-sub-title">{{ item.title }}</span>
            </transition>
          </MenuItem>
        </div>
      </Menu>
    </div>
    <i
      class="icon-box"
      :class="isOpen ? '' : 'icon-box-up'"
      :style="{ left: isOpen ? '180px' : '53px' }"
      @click="clickIcon"
    />
  </div>
</template>

<script>
export default {
  name: "menu-side",
  props: {
    // 列表数据-用于定制化，暂时未使用
    list: {
      type: Array,
      default: () => [],
    },
    // 当前选中的menuItem
    activeMenu: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 默认展开数据集合
      openNames: [],
      // 默认列表集合，默认是查品牌时使用
      defaultMenuList: [
        {
          key: "线下洞察",
          name: "线下洞察",
          title: "线下",
          children: [
            {
              key: "mendian",
              name: "门店分布",
              icon: require("@/assets/images/icon/menu_md.png"),
              isComponet: false,
            },
            {
              key: "juke",
              name: "周边客群",
              icon: require("@/assets/images/icon/menu_jk.png"),
              isComponet: false,
            },
            {
              key: "mddf",
              name: "门店到访",
              icon: require("@/assets/images/icon/menu_df.png"),
              isComponet: false,
            },
            {
              key: "kehu",
              name: "客户画像",
              icon: require("@/assets/images/icon/menu_kh.png"),
              isComponet: false,
            },
            {
              key: "mdxl",
              name: "门店销量",
              icon: require("@/assets/images/icon/menu_xl.png"),
              isComponet: false,
            },
          ],
        },
        {
          key: "线上营销",
          name: "线上营销",
          title: "线上",
          children: [
            {
              key: "CreativityPut",
              name: "创意投放",
              icon: require("@/assets/images/icon/menu_tf.png"),
              isComponet: false,
            },
            {
              key: "CreativityVideo",
              name: "创意视频",
              icon: require("@/assets/images/icon/menu_sp.png"),
              isComponet: false,
            },
            {
              key: "CreativityAscribe",
              name: "创意归因",
              icon: require("@/assets/images/icon/menu_gy.png"),
              isComponet: false,
            },
          ],
        },
        {
          key: "depth",
          name: "深度分析",
          title: "深度分析",
          children: [
            {
              key: "offlineAnalysis",
              name: "线下数据分析",
              icon: require("@/assets/images/icon/menu_xx.png"),
              isComponet: true,
            },
            {
              key: "onlineAnalysis",
              name: "线上营销分析",
              icon: require("@/assets/images/icon/menu_xs.png"),
              isComponet: true,
            },
          ],
        },
        // {
        //   key: "深度分析",
        //   name: "深度分析",
        //   title: "深度",
        //   children: [],
        // },
      ],
      // 是否最小化列表
      isOpen: true,
    };
  },
  computed: {
    menuList() {
      return (this.list?.length ? this.list : this.defaultMenuList) || [];
    },
  },
  mounted() {
    // 默认列表数据全部展示
    this.openNames = this.menuList.map((item) => item.key);
    this.$nextTick(() => {
      this.$refs.menu.updateOpened();
    });
  },
  methods: {
    // 切换展开收起状态事件
    clickIcon() {
      this.isOpen = !this.isOpen;
      this.$emit("isOpen", this.isOpen);
    },
    // 列表选中事件
    onSelect(name) {
      let isComponet = false;
      this.menuList.forEach((item) => {
        if (item.children) {
          let childIndex = item.children?.findIndex(
            (child) => child.key === name
          );
          if (childIndex > -1) {
            isComponet = item.children[childIndex].isComponet;
          }
        } else {
          isComponet = item.isComponet;
        }
      });
      this.$emit("onselect", name, isComponet);
    },
  },
};
</script>

<style lang="less" scoped>
.menu-side-box {
  @cap: 103px;
  height: calc(~"100% - @{cap}");
  position: relative;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding-top: 47px;
  margin-top: 2px;
  transition: width 0.3s;
  box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 12%);
  z-index: 999;
  .menu-view-box {
    overflow-y: auto;
    height: 100%;
  }
  .menu-box.ivu-menu-vertical {
    transition: width 0.3s;
    .ivu-menu-item-active:not(.ivu-menu-submenu) {
      background: rgba(81, 57, 255, 0.04);
      font-weight: 500;
      color: #000000;
    }
    .ivu-menu-submenu {
      /deep/ .ivu-menu-submenu-title {
        padding: 10px 24px;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        line-height: 22px;
        .ivu-icon {
          transition: all 0.3s;
        }
        .menu-sub-title {
          transition: width 0.3s ease-in-out;
        }
        .ivu-menu-submenu-title-icon {
          right: 16px;
        }
      }
      /deep/ .ivu-menu-item {
        padding: 10px 24px;
        padding-left: 30px !important;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        line-height: 20px;
        &:hover {
          background: rgba(81, 57, 255, 0.04);
          font-weight: 500;
          color: #000000;
        }
      }
      .menu-item-icon {
        width: 14px;
        height: 14px;
        vertical-align: middle;
        margin-right: 5px;
      }
      .menu-item-title {
        position: absolute;
        transition: all 0.3s;
      }
    }
    .menu-sub-nochild {
      padding: 10px 24px;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.8);
      // line-height: 20px;
      &:hover {
        background: rgba(81, 57, 255, 0.04);
        font-weight: 500;
        color: #000000;
      }
      .menu-sub-title {
        transition: width 0.3s ease-in-out;
      }
      .ivu-menu-submenu-title-icon {
        right: 16px;
      }
    }
  }
  // 收起状态下列表样式
  .menu-box.menu-minimize-box.ivu-menu-vertical {
    /deep/ .ivu-menu-submenu-title {
      padding: 10px 18px 10px 24px;
      .ivu-icon {
        display: none;
        transition: opacity 0.3s;
      }
    }
    .menu-sub-nochild {
      padding: 10px 18px 10px 24px;
    }
  }
  .ivu-menu-vertical.ivu-menu-light:after {
    display: none;
  }
  .ivu-menu-item-active:not(.ivu-menu-submenu):after {
    display: none;
  }
  // 展开及收起icon
  .icon-box {
    width: 41px;
    height: 41px;
    position: absolute;
    top: 11px;
    z-index: 1000;
    background: url("../assets/images/icon/menu_down.png") no-repeat center/100%;
  }
  .icon-box-up {
    background: url("../assets/images/icon/menu_up.png") no-repeat center/100%;
  }

  /** 动画进行时的class **/
  .zoom-enter-active,
  .zoom-leave-active {
    transition: all 0.15s cubic-bezier(0.42, 0, 0.34, 1.55);
  }
  /** 设置进场开始的状态和离场结束的状态，都是缩放到0 **/
  .zoom-enter,
  .zoom-leave-to {
    transform: scale(0);
  }

  /** 设置进场结束的状态和离场开始的状态, 都是缩放到1 **/
  .zoom-enter-to,
  .zoom-leave {
    transform: scale(1);
  }
}
</style>
