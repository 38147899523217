var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "page-content" },
      [
        _vm._l(_vm.list, function (item) {
          return _c("div", { key: item.id, staticClass: "item" }, [
            _c("div", { staticClass: "item-top" }, [
              _c("img", {
                staticClass: "item-icon",
                attrs: { height: "36", width: "36", src: item.icon, alt: "" },
              }),
              _c("p", [
                _c("span", { staticClass: "item-title" }, [
                  _vm._v(_vm._s(item.product)),
                ]),
                _c("span", { staticClass: "line" }, [_vm._v("|")]),
                _c("span", [_vm._v(_vm._s(item.desc))]),
              ]),
              _c("img", {
                staticClass: "tool",
                attrs: { width: "54", height: "30", src: item.number, alt: "" },
              }),
            ]),
            _c(
              "div",
              { staticClass: "item-con", class: { rtl: item.id % 2 === 0 } },
              [
                _c("div", { staticClass: "item-left" }, [
                  _c("p", [_vm._v(_vm._s(item.content))]),
                  _c(
                    "a",
                    {
                      staticClass: "more-btn",
                      attrs: { href: item.href, target: "_blank" },
                    },
                    [
                      _vm._v("了解更多 "),
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/depthAnalysis/more.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "item-right" }, [
                  _c("img", {
                    attrs: {
                      width: "438",
                      height: "268",
                      src: item.img,
                      alt: "",
                    },
                  }),
                ]),
              ]
            ),
          ])
        }),
        _vm._m(1),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-top" }, [
      _c("p", { staticClass: "page-top-title" }, [_vm._v("线下数据分析")]),
      _c("p", { staticClass: "page-top-con" }, [
        _vm._v(" 通过产品+数据+服务的矩阵方式，契合不同规模客户的不同需求，"),
        _c("br"),
        _vm._v("为客户提供兼顾价格、效果、时间的最适宜的解决方案 "),
      ]),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://www.changjing.com.cn/", target: "_blank" },
        },
        [
          _vm._v("了解更多"),
          _c("img", { attrs: { src: "/images/home/right.png", alt: "" } }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "customer" }, [
      _c("div", { staticClass: "customer-title" }, [_vm._v("我们的客户")]),
      _c("p", { staticClass: "desc" }, [
        _vm._v(
          " 众多行业，涵盖零售，地产、金融、广告传媒、交通出行、物流快递、金融保险、"
        ),
        _c("br"),
        _vm._v("城市和政府等行业数百家企业与政府的共同选择 "),
      ]),
      _c("img", {
        attrs: {
          width: "1002",
          height: "245",
          src: require("../../assets/images/depthAnalysis/customer-logo.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }